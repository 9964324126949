import checkoutFormModel from './checkoutFormModel';

const {
  formField: {
    nif,
    firstName,
    lastName,
    email,
    phone,
    address1,
    city,
    zipcode,
    country,
    sortida,
    comments,
    acceptDataAcceptance,
  },
} = checkoutFormModel;

export default {
  [nif.name]: '36525468L',
  [firstName.name]: 'lluis',
  [lastName.name]: 'rusca',
  [email.name]: 'lluis1@gmail.com',
  [phone.name]: '234234',
  [address1.name]: 'asdasd',
  [city.name]: 'asdasd',
  [zipcode.name]: '08233',
  [country.name]: 'cat',
  [sortida.name]: 'des de casa',
  [comments.name]: 'asdas jashdg askjhfg daksh fkad f',
  [acceptDataAcceptance.name]: false,
};
