import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/styles";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import {
  PayButton,
  PayButtonSublabel,
  PayButtonSublabelWrapper,
  PayButtonWrapper,
} from "./styles";
import { orderService } from "../../modules/Order/OrderService";
import { getCart } from "../../store/modules/order/selectors";
import { useUIContext } from "../../context/UIContext";
import StayInfo from "../BookIt/StayInfo";
import { colFlex, flexCenter } from "../../styles/themes/commonStyles";
import CartResumeCost from "./CartResumeCost";
import { StyledActionLoadingButton } from "../../styles/mui/StyledActionLoadingButton";

const _ = require("lodash");

function GoToCustomerInputData(t, setCartOpen) {
  const navigate = useNavigate();

  return (
    <PayButtonWrapper>
      <PayButton
        onClick={() => {
          setCartOpen(false);
          navigate("/checkout");
        }}
      >
        {t("cart.actions.checkout")}
      </PayButton>
      <PayButtonSublabelWrapper>
        <PayButtonSublabel>
          {t("cart.labels.checkout_tooltip_label")}
        </PayButtonSublabel>
      </PayButtonSublabelWrapper>
    </PayButtonWrapper>
  );
}

function Cart({}) {
  const { t } = useTranslation("general");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const [isLoadingCheckout, setIsLoadingCheckout] = useState(false);
  const { cartOpen, setCartOpen } = useUIContext();

  const cart = useSelector(getCart);
  const cartPrice = orderService.calculateCartPrice(cart);

  const gotoCheckout = (e) => {
    setCartOpen(false);
    navigate("/checkout");
  };

  return (
    <Box display="flex" flexDirection="column" sx={{ p: "16px" }}>
      {cart.length > 0 ? (
        <>
          <Box
            sx={{
              ...flexCenter,
              alignItems: "center",
              ...colFlex,
              px: "24px",
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{
                mb: "24px",
                color: theme.palette.secondary.main,
              }}
            >
              El teu itinerari
            </Typography>
            <Typography
              sx={{
                mb: "24px",
              }}
              align="center"
              variant={matches ? "h5" : "h3"}
            >
              Pots afegir més estades si vols fer una travessa de diversos dies
            </Typography>
            <Box
              sx={{
                ...flexCenter,
              }}
            >
              <StyledActionLoadingButton
                fullWidth
                loading={isLoadingCheckout}
                onClick={() => {
                  setCartOpen(false);
                  navigate("/");
                }}
                variant="text"
              >
                Afegeix Estades
              </StyledActionLoadingButton>
            </Box>
          </Box>
          <Paper
            sx={{
              px: 4,
              mt: 4,
            }}
          >
            {/* eslint-disable-next-line array-callback-return */}
            {cart.map((item, i) => (
              <StayInfo
                key={`cart_item_'${item.id}`}
                index={i}
                stay={item}
                canDelete
              />
            ))}
          </Paper>
          <Box
            sx={{
              px: "32px",
            }}
          >
            <CartResumeCost />
          </Box>
          {cart.length > 0 && (
            <Box
              sx={{
                ...flexCenter,
                px: "48px",
                my: "32px",
              }}
            >
              <StyledActionLoadingButton
                fullWidth
                loading={isLoadingCheckout}
                onClick={gotoCheckout}
                variant="text"
              >
                Ves a caixa
              </StyledActionLoadingButton>
            </Box>
          )}
        </>
      ) : (
        <Box
          sx={{
            ...flexCenter,
            alignItems: "center",
            ...colFlex,
            px: "24px",
          }}
        >
          <Typography
            variant="subtitle1"
            sx={{
              mb: "24px",
              color: theme.palette.secondary.main,
            }}
          >
            Comença a prepararar el teu itinerari.
          </Typography>
        </Box>
      )}
    </Box>
  );
}

export const forfaitToCartStay = (site, date, ...forfaits) => ({
  site_name: site.site_name,
  site_id: site.site_id,
});

export default React.memo(Cart);
