import React, { useEffect, useRef } from "react";
import { useLocation, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";

import {
  EmptySectionButton,
  EmptySectionButtonSpan,
  EmptySectionButtonTextWrapper,
  LineSeparator,
} from "../../components/Shared/styles";
import BookIt from "../../components/BookIt/BookIt";
import Status from "../../components/Refuge/Status";
import Events from "../../components/Refuge/Events";
import { appendRefugeToCart } from "../../store/modules/order/actions";
import SectionProductPrices from "../../components/Refuge/SectionProductPrices";
import { StayProvider } from "../../context/StayContext";
import { displayOnMobile, flexBetween } from "../../styles/themes/commonStyles";
import { useUIContext } from "../../context/UIContext";
import { H1 } from "../../styles/Headings.styled";
import Footer from "../../components/Layout/Footer/Footer";
import SectionAmenities from "../../components/Refuge/SectionAmenities";
import BubblesLoading from "../../components/Shared/BubblesLoading";
import SkeletonRefugeDetailScreen from "./SkeletonRefugeDetailScreen";
import RefugeDetailScreenFooter from "./components/RefugeDetailScreen.Footer";
import { useRefugeDetailScreen } from "./useRefugeDetailScreen";
import { usePage } from "../../hooks/usePage";

const _ = require("lodash");

export function NoProducts() {
  return (
    <div style={{ marginBottom: "12px", marginTop: "32px" }}>
      <EmptySectionButton
        type="button"
        aria-busy="false"
        data-veloute="translation-button"
      >
        <EmptySectionButtonSpan>
          <EmptySectionButtonTextWrapper>
            No hi ha productes disponibles per aquest dia.
          </EmptySectionButtonTextWrapper>
        </EmptySectionButtonSpan>
      </EmptySectionButton>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  mainLeft: {
    flexDirection: "row",
    [theme.breakpoints.up("sm")]: {
      width: "63.60%",
    },
  },
  mainRight: {
    width: "36.40%",
  },
  appBarTransparent: {
    backgroundColor: "rgba(67, 129, 168,0.5)",
  },
  bookIt: {
    padding: "24px",
    width: "100%",
    border: "1.5px solid rgb(242, 242, 242)",
    borderRadius: "15px",
    boxShadow: "rgba(0, 0, 0, 0.2) 0px 5px 20px",
    mb: 4,
  },
}));

function RefugeDetailScreen({ screen }) {
  usePage(screen);
  const { slug } = useParams();
  const {
    isLoadingSearch,
    setIsLoadingSearch,
    fetchRefuge,
    refuge,
    products,
    isLoadingProducts,
  } = useRefugeDetailScreen(slug);

  const { isMobile, cartOpen } = useUIContext();
  const classes = useStyles();

  const { state: place } = useLocation();

  const myRef = useRef(null);

  useEffect(() => {
    if (refuge) {
      setIsLoadingSearch(false);
    }
    if (!refuge) {
      async function fetchData() {
        await fetchRefuge(slug);
      }

      fetchData();
    }
  }, [slug]);

  const scrollToRef = () => {
    myRef.current.scrollIntoView();
  };

  return (
    <>
      {isLoadingSearch ? (
        <SkeletonRefugeDetailScreen />
      ) : (
        <StayProvider>
          <Container disableGutters maxWidth="lg">
            <Box
              sx={{
                position: "relative",
                pb: 4,
              }}
            >
              <Box
                sx={{
                  ...flexBetween,
                  position: "relative",
                }}
              >
                <Box
                  sx={{
                    width: "60%",
                    ...(isMobile && {
                      width: "100%",
                      mb: 4,
                    }),
                  }}
                >
                  <Grid container spacing={0} justify="flex-start">
                    <Grid item xxs={12} order={{ xxs: 2 }}>
                      {refuge.site.name && (
                        <Box
                          sx={{
                            ...flexBetween,
                            pt: { xxs: "24px", sm: "24px" },
                            pb: { xxs: "16px", sm: "0" },
                            pl: { xxs: "24px", sm: "40px" },
                            pr: { xxs: "24px", sm: "24px" },
                          }}
                        >
                          <Box>
                            <H1>{refuge.site.name}</H1>
                            <Typography
                              variant="body2"
                              fontWeight="600"
                              color="primary.dark"
                            >
                              {refuge.site.address.city}
                            </Typography>
                          </Box>
                          <Box>
                            <Status
                              statusCode={refuge.status}
                              isLoading={isLoadingSearch}
                              size="small"
                            />
                          </Box>
                        </Box>
                      )}
                    </Grid>
                    <Grid item xxs={12} order={{ xxxs: 1, sm: 2 }}>
                      <Box
                        sx={{
                          aspectRatio: "10 / 7",
                          display: "flex",
                          pt: { xxxs: 0, sm: "24px" },
                          pl: { xxxs: 0, sm: "40px" },
                          pr: { xxxs: 0, sm: "24px" },
                        }}
                      >
                        <Box
                          component="img"
                          sx={{
                            height: "100",
                            width: "100%",
                            borderRadius: { xxs: 0, sm: "8px" },
                            objectFit: "cover",
                          }}
                          alt={refuge.site.name}
                          src={refuge.site.images}
                        />
                      </Box>
                    </Grid>
                    <Grid item xxs={12} order={{ xxs: 3 }}>
                      <Box
                        sx={{
                          pl: { xxs: "24px", sm: "40px" },
                          pr: { xxs: "24px", sm: "24px" },
                          pt: { xxs: 0, sm: "40px" },
                          pb: { xxs: "24px", sm: "24px" },
                        }}
                      >
                        {refuge.site.propietat && (
                          <Typography variant="body2" color="primary.dark">
                            Propietari: {refuge.site.propietat}
                          </Typography>
                        )}
                        {refuge.site.url && (
                          <a
                            target="_blank"
                            href={`http://${refuge.site.url.substring(
                              refuge.site.url.lastIndexOf("/") + 1
                            )}`}
                            rel="noopener noreferrer"
                          >
                            <Typography variant="body2" color="primary.dark">
                              {refuge.site.url.substring(
                                refuge.site.url.lastIndexOf("/") + 1
                              )}
                            </Typography>
                          </a>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                  <Box
                    sx={{
                      pl: { xxs: "24px", sm: "40px" },
                      pr: { xxs: "24px", sm: "24px" },
                    }}
                  >
                    <LineSeparator />
                    <Box
                      section-id="amenities"
                      sx={{
                        pt: { xxs: "16px", sm: "32px" },
                        pb: { xxs: "16px", sm: "32px" },
                      }}
                    >
                      <SectionAmenities
                        isMobile={isMobile}
                        site={refuge.site}
                      />
                    </Box>
                    <LineSeparator />
                    {refuge.events.length > 0 && (
                      <>
                        <Box
                          section-id="events"
                          sx={{
                            pt: { xxs: "16px", sm: "32px" },
                            pb: { xxs: "16px", sm: "32px" },
                          }}
                        >
                          <Events events={refuge.events} />
                        </Box>
                        <LineSeparator />
                      </>
                    )}
                    {refuge.site.description && (
                      <Box
                        section-id="description"
                        sx={{
                          pt: { xxs: "16px", sm: "32px" },
                          pb: { xxs: "16px", sm: "32px" },
                        }}
                      >
                        <span
                          className="content"
                          dangerouslySetInnerHTML={{
                            __html: refuge.site.description,
                          }}
                        />
                      </Box>
                    )}
                    <>
                      <LineSeparator />
                      <Box
                        id="priceTable"
                        ref={myRef}
                        section-id="products"
                        sx={{
                          pt: { xxs: "16px", sm: "32px" },
                          pb: { xxs: "16px", sm: "32px" },
                        }}
                      >
                        {isLoadingProducts ? (
                          <BubblesLoading />
                        ) : (
                          <>
                            {products && products.length > 0 ? (
                              <SectionProductPrices
                                products={products}
                                appendRefugeToCart={appendRefugeToCart}
                                refuge={refuge}
                              />
                            ) : (
                              <NoProducts />
                            )}
                          </>
                        )}
                      </Box>
                    </>
                  </Box>
                </Box>
                {!isMobile && (
                  <Box
                    sx={{
                      width: "36.33%",
                      pr: { xxs: "24px", sm: "40px" },
                    }}
                  >
                    <Box
                      sx={{
                        position: "sticky",
                        top: "24px",
                        width: "100%",
                        mt: "48px",
                      }}
                    >
                      <Box className={classes.bookIt}>
                        <BookIt
                          handleSelectProducts={scrollToRef}
                          refuge={refuge}
                          isLoadingProducts={isLoadingProducts}
                          fetchRefuge={fetchRefuge}
                        />
                      </Box>
                    </Box>
                  </Box>
                )}
                {isMobile && !cartOpen && (
                  <RefugeDetailScreenFooter
                    scrollToRef={scrollToRef}
                    refuge={refuge}
                    fetchRefuge={fetchRefuge}
                  />
                )}
                {/* <BookIt
                  scrollToRef={scrollToRef}
                  refuge={refuge}
                  isLoadingProducts={isLoadingProducts}
                  setIsLoadingProducts={setIsLoadingProducts}
                /> */}
              </Box>
            </Box>
            <Box
              sx={{
                ...displayOnMobile,
                pb: (theme) => `${theme.global.mobileFooterHeight}px`,
              }}
            >
              <Footer />
            </Box>
          </Container>
        </StayProvider>
      )}
    </>
  );
}

export default React.memo(RefugeDetailScreen);
