import { useTranslation } from "react-i18next";
import { Container, Form } from "react-bootstrap";
import React from 'react';

function Language() {
	const { i18n } = useTranslation();

	const languages = [
		{
			code: "ca",
			name: "català",
			countryCode: "ca"
		},
		{
			code: "es",
			name: "español",
			countryCode: "es"
		},
		{
			code: "fr",
			name: "francais",
			countryCode: "fr"
		},
		{
			code: "en",
			name: "english",
			countryCode: "gb"
		},
	];

	return (
		<div className="section language">
			<Container>
				<Form.Select
					defaultValue={i18n.resolvedLanguage}
					onChange={e => {
						i18n.changeLanguage(e.target.value);
					}}
				>
					{languages.map(({ code, name, countryCode }) => {
						return (
							<option
								key={countryCode}
								value={code}
							>
								{name}
							</option>
						);
					})}
				</Form.Select>
			</Container>
		</div>
	);
}

export default React.memo(Language);
