import { PrepareStayFromCart } from "../../../modules/Order/PrepareStayFromCart";

const _ = require("lodash");

export const getTpvId = (state) => state.order.operId;
export const getBookingId = (state) => state.order.bookingId;

export const getOrderId = (state) => state.order.orderId;

export const getCart = (state) => state.order.cart;

export const getCustomer = (state) => state.order.customer;

export const getPostReservation = (state) => {
  const { customer, cart } = state.order;
  const mapped = cart.map(PrepareStayFromCart);
  let details = "";
  mapped.map((stay) => {
    const rateDetails = stay[0].guests.reduce(
      (prevVal, currVal, idx) =>
        idx === 0
          ? `${currVal.rate.description}`
          : `${prevVal}, ${currVal.rate.description}`,
      ""
    );
    details += `\\n${rateDetails}`;
  });

  customer.comments += `\\n\\r\\n\\r${details}`;
  return {
    customer: {
      ...customer,
    },
    stays: _.flatten(mapped),
  };
};

export const getTpvForm = (state) => state.order.tpvForm;
