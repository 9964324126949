import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Divider } from "@mui/material";
import Stack from "@mui/material/Stack";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import Icon from "@mui/material/Icon";
import { useDispatch } from "react-redux";
import { colFlex, flexBetween } from "../../styles/themes/commonStyles";
import { orderService } from "../../modules/Order/OrderService";
import { removeCartItem } from "../../store/modules/order/actions";
import { formatPrice } from "../../utils/formatPrice";

const _ = require("lodash");

const useStyles = makeStyles((theme) => ({
  regimPopover: {
    padding: "24px",
  },
}));

function StayNightsDisplay({ stay, nights }) {
  return (
    <Typography variant="smallBold">
      {`${nights} nits a ${stay.site.name}`}
    </Typography>
  );
}

function StayInfo({ index, stay, isShort, canDelete }) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const nights = orderService.calculateNights(stay);
  const showSiteNightsText = (r, nights) =>
    nights > 0 ? `${nights} nits a ${r.site.name}` : `${r.site.name}`;
  const calculateStayPrice = (stay, product) =>
    product.price * product.quantity * nights;

  function handleDeleteItem() {
    dispatch(removeCartItem(index));
  }

  function stayTotal(stay) {
    const subtotal = stay.products.reduce(
      (accumulator, product) => accumulator + product.price * product.quantity,
      0
    );

    return (
      <Box
        sx={{
          ...flexBetween,
          pt: 2,
        }}
      >
        <Typography variant="bodyBold">
          {formatPrice(subtotal)} x {nights} nits
        </Typography>
        <Typography variant="bodyBold">
          {formatPrice(subtotal * nights)}
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        mb: 2,
      }}
    >
      <Box sx={flexBetween}>
        {!isShort && <StayNightsDisplay stay={stay} nights={nights} />}
        {canDelete && (
          <Icon
            sx={{
              color: (theme) => theme.palette.btn_action.main,
            }}
          >
            <DeleteOutlineOutlinedIcon onClick={() => handleDeleteItem()} />
          </Icon>
        )}
      </Box>
      <Box
        sx={{
          mt: "12px",
        }}
      >
        <Stack
          direction="row"
          divider={<Divider orientation="vertical" flexItem />}
          justifyContent="flex-start"
          alignItems="center"
          spacing={0}
          sx={{
            p: 0,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginRight: "8px",
            }}
          >
            <Typography variant="body2" color="primary.dark">
              {new Intl.DateTimeFormat("ca-ES", {
                weekday: "long",
              }).format(moment(stay.from))}
            </Typography>
            <Typography variant="body">
              {new Intl.DateTimeFormat("ca-ES", { day: "numeric" }).format(
                moment(stay.from)
              )}{" "}
              {_.capitalize(
                new Intl.DateTimeFormat("ca-ES", {
                  month: "short",
                }).format(moment(stay.from))
              )}
            </Typography>
          </Box>
          <Box
            sx={{
              ...colFlex,
              ml: "8px",
            }}
          >
            <Typography variant="body2" color="primary.dark">
              {new Intl.DateTimeFormat("ca-ES", {
                weekday: "long",
              }).format(moment(stay.to))}
            </Typography>
            <Typography variant="body">
              {new Intl.DateTimeFormat("ca-ES", { day: "numeric" }).format(
                moment(stay.to)
              )}{" "}
              {_.capitalize(
                new Intl.DateTimeFormat("ca-ES", {
                  month: "short",
                }).format(moment(stay.to))
              )}
            </Typography>
          </Box>
        </Stack>
      </Box>
      <Box
        sx={{
          ...colFlex,
          mt: 1,
        }}
      >
        {stay.products.map((product) => (
          <Box key={product.product_name} sx={flexBetween}>
            <Box sx={colFlex}>
              <Typography variant="body">
                {product.quantity} x {product.product_name}
              </Typography>
              <Typography variant="xSmall">
                {" "}
                {product.rang_edat_name}
                {" (Tarifa "}
                {product.membership_name})
              </Typography>
            </Box>
            <Typography variant="body">
              {formatPrice(product.price * product.quantity)}
            </Typography>
          </Box>
        ))}
        <Box sx={{}} />
        {stayTotal(stay)}
      </Box>
      <Divider
        variant="middle"
        sx={{
          my: "24px",
        }}
      />
    </Box>
  );
}

export default React.memo(StayInfo);
