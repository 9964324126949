import React from "react";
import { Grid } from "@mui/material";

import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import InputField from "../../../components/FormFields/InputField";
import TextareaField from "../../../components/FormFields/TextareaField";
import CheckboxField from "../../../components/FormFields/CheckboxField";

const useStyles = makeStyles((theme) => ({
  inputs: {
    padding: "12px",
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing.unit * 3,
    marginLeft: theme.spacing.unit,
  },
}));

function CustomerDataForm(props) {
  const classes = useStyles();

  const {
    formField: {
      nif,
      firstName,
      lastName,
      address1,
      address2,
      city,
      email,
      phone,
      state,
      zipcode,
      country,
      sortida,
      comments,
      acceptDataAcceptance,
    },
  } = props;

  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          paddingTop: "24px",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xxs={12} xs={6} md={6}>
            <InputField name={nif.name} label={nif.label} fullWidth />
          </Grid>
          <Grid item xxs={12} xs={6} md={6} />
          <Grid item xxs={12} xs={6} md={6}>
            <InputField
              name={firstName.name}
              label={firstName.label}
              fullWidth
            />
          </Grid>
          <Grid item xxs={12} xs={6} md={6}>
            <InputField name={lastName.name} label={lastName.label} fullWidth />
          </Grid>
          <Grid item xxs={12} xs={6} md={6}>
            <InputField name={phone.name} label={phone.label} fullWidth />
          </Grid>
          <Grid item xxs={12} xs={6} md={6}>
            <InputField name={email.name} label={email.label} fullWidth />
          </Grid>
          <Grid item xxs={12} xs={6}>
            <InputField name={address1.name} label={address1.label} fullWidth />
          </Grid>
          <Grid item xxs={12} xs={6}>
            <InputField name={address2.name} label={address2.label} fullWidth />
          </Grid>
          <Grid item xxs={12} xs={6}>
            <InputField name={city.name} label={city.label} fullWidth />
          </Grid>
          <Grid item xxs={12} xs={6}>
            <InputField name={state.name} label={state.label} fullWidth />
          </Grid>
          <Grid item xxs={12} xs={6}>
            <InputField name={zipcode.name} label={zipcode.label} fullWidth />
          </Grid>
          <Grid item xxs={12} xs={6}>
            <InputField name={country.name} label={country.label} fullWidth />
          </Grid>
          <Grid item xxs={12}>
            <TextareaField
              name={comments.name}
              label={comments.label}
              fullWidth
            />
          </Grid>
          <Grid item xxs={12}>
            <CheckboxField
              name={acceptDataAcceptance.name}
              label={acceptDataAcceptance.label}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default React.memo(CustomerDataForm);
