import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Paper from "@mui/material/Paper";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useSearchContext } from "../../context/SearchContext";
import { useUIContext } from "../../context/UIContext";
import { colFlex, flexCenter } from "../../styles/themes/commonStyles";
import CalendarRangePicker from "../LocationSearch/CalendarRangePicker";
import { updateSearchFilters } from "../../store/modules/search/actions";
import { useStayContext } from "../../context/StayContext";
import StayInfo from "./StayInfo";
import { appendRefugeToCart } from "Store/modules/order/actions";
import { StyledActionLoadingButton } from "Styles/mui/StyledActionLoadingButton";
import { useMountEffect } from "Hooks/useMountEffect";
import HostsGroupedBtn from "./HostsGroupedBtn";
import BookItActionButton from "./BookItActionButton";

const _ = require("lodash");

function BookIt({
  handleSelectProducts,
  refuge,
  isLoadingProducts,
  fetchRefuge,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isMobile, setCartOpen, cartOpen } = useUIContext();

  const {
    isLoadingSearch,
    setIsLoadingSearch,
    isNewSearch,
    setIsNewSearch,
    guests,
    setGuests,
  } = useSearchContext();

  const {
    isBookingEnabled,
    updateIsBookingEnabled,
    resetProducts,
    shoppingData,
    isLoadingAddToCart,
    setIsLoadingAddToCart,
    handleDatesFeedback,
    datesFeedbackText,
  } = useStayContext();

  const [] = useState("");
  const [isBooked, setIsBooked] = useState(false);

  useMountEffect(() => handleDatesFeedback(refuge));

  const styles = {
    logo: {
      width: "6vw",
      marginRight: "3rem",
      minWidth: "6rem",
      ...(isMobile && {
        minWidth: "0.5rem ",
      }),
    },
    line: {
      borderTop: "1px solid rgb(230, 229, 229)",
      mb: "0px",
    },
    image_div: {
      marginTop: 4,
    },
    card: {
      padding: "24px",
      width: "100%",
      border: "1.5px solid rgb(242, 242, 242)",
      borderRadius: "15px",
      boxShadow: "rgba(0, 0, 0, 0.2) 0px 5px 20px",
      mb: 4,
    },
    card_top: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    price_div: {
      fontSize: "1.25rem",
      fontWeight: "bold",
      color: "black",
      marginLeft: "10px",
    },
    card_rating: {
      alignItems: "center",
      display: "flex",
    },
    form: {
      display: "flex",
      flexDirection: "column",
      mb: 4,
      border: "1px solid",
      borderColor: (theme) => theme.palette.black,
      borderRadius: 2,
      padding: "12px 12px",
    },
    stay: {
      mt: "32px",
      mb: 3,
    },
    input: {
      fontSize: "0.75rem",
      fontWeight: "bold",
      color: "rgba(0, 0, 0, 0.842)",
      padding: "0.75rem",
      height: "2.75rem",
      mt: 1,
    },
  };

  const handleDateChange = () => {
    setIsNewSearch(true);
  };

  const handleBook = () => {
    setIsLoadingAddToCart(true);
    dispatch(
      appendRefugeToCart({
        refuge: {
          ...shoppingData,
          products: shoppingData.products.filter((guest) => guest.quantity > 0),
        },
      })
    );
    setCartOpen(true);
    setIsBooked(true);
    setIsLoadingAddToCart(false);
  };

  function handleError() {
    dispatch({
      type: "error",
      message: `${msg}`,
      title: "Booking Failed",
      position: "topR",
    });
  }

  const gotoCheckout = (e) => {
    setCartOpen(false);
    navigate("/checkout");
  };

  const handleChangeHosts = (event) => {
    const people = parseInt(event.target.value, 10) || 1;
    setGuests(people);
    setIsNewSearch(true);
    dispatch(updateSearchFilters({ filters: { peopleNumber: people } }));
  };

  const incrementHosts = () => {
    setGuests(guests + 1);
    dispatch(updateSearchFilters({ filters: { peopleNumber: guests + 1 } }));
  };

  const decrementHosts = () => {
    if (guests > 1) {
      setGuests(guests - 1);
      dispatch(updateSearchFilters({ filters: { peopleNumber: guests - 1 } }));
    }
  };

  const handleSearch = async () => {
    setIsLoadingSearch(true);
    await fetchRefuge(refuge.site.slug);
    setIsLoadingSearch(false);
  };

  useEffect(() => {
    updateIsBookingEnabled();
  }, [shoppingData]);

  console.log(isNewSearch);
  console.log(isBookingEnabled);
  return (
    <>
      {!cartOpen ? (
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Box
            sx={{
              ...flexCenter,
              alignItems: "center",
              mb: "24px",
            }}
          >
            <Typography
              sx={{
                fontSize: (theme) => theme.typography.fontSizes.l,
                lineHeight: "26px",
              }}
              component="div"
            >
              Prepara el teu itinerari
            </Typography>
          </Box>
          <Box sx={styles.form}>
            <Typography variant="xSmall" color="primary.dark">
              Dates
            </Typography>
            <Box sx={flexCenter}>
              <CalendarRangePicker
                displayFormat="DD/MM/YYYY"
                handleChange={handleDateChange}
              />
            </Box>
            <Typography variant="xSmall" color="primary.dark">
              Hostes
            </Typography>
            <Box
              sx={{
                ...flexCenter,
                mb: "8px",
              }}
            >
              <HostsGroupedBtn
                onChange={handleChangeHosts}
                onIncrement={incrementHosts}
                onDecrement={decrementHosts}
                hosts={guests}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            {isNewSearch || !isBookingEnabled ? (
              <BookItActionButton
                isLoadingSearch={isLoadingSearch}
                handleSearch={handleSearch}
                buttonText="Consulta"
              />
            ) : (
              <StyledActionLoadingButton
                fullWidth
                loading={isLoadingProducts}
                onClick={handleSelectProducts}
                variant="text"
              >
                Selecciona règims
              </StyledActionLoadingButton>
            )}
            {!isNewSearch && (
              <Box
                sx={{
                  ...flexCenter,
                  marginTop: "8px",
                }}
              >
                <Typography variant="smallBold">{datesFeedbackText}</Typography>
              </Box>
            )}
          </Box>
          {shoppingData.products.length > 0 && (
            <Box
              sx={{
                ...colFlex,
                ...styles.stay,
              }}
            >
              <Typography
                sx={{
                  fontSize: (theme) => theme.typography.fontSizes.l,
                  lineHeight: "26px",
                }}
                component="div"
              >
                La teva estada
              </Typography>
              <Paper
                sx={{
                  width: "100%",
                }}
              >
                <StayInfo stay={shoppingData} isShort canDelete={false} />
              </Paper>
              <Box
                sx={{
                  mt: 1,
                }}
              >
                {isBooked ? (
                  <StyledActionLoadingButton
                    fullWidth
                    disabled={!isBookingEnabled}
                    loading={isLoadingAddToCart}
                    onClick={gotoCheckout}
                    variant="text"
                  >
                    Checkout
                  </StyledActionLoadingButton>
                ) : (
                  <>
                    <StyledActionLoadingButton
                      fullWidth
                      disabled={!isBookingEnabled.status}
                      loading={isLoadingAddToCart}
                      onClick={handleBook}
                      variant="text"
                    >
                      Reserva
                    </StyledActionLoadingButton>
                    {isBookingEnabled.msg && (
                      <Box
                        sx={{
                          ...flexCenter,
                          marginTop: 2,
                        }}
                      >
                        <Typography variant="smallBold">
                          {isBookingEnabled.msg}
                        </Typography>
                      </Box>
                    )}
                  </>
                )}
              </Box>
            </Box>
          )}
        </LocalizationProvider>
      ) : (
        <></>
      )}
    </>
  );
}

export default React.memo(BookIt);
