import styled from 'styled-components';
import { EventDescriptionTitle } from '../Refuge/Events/styles';

export const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  align-items: left;
`;

export const PriceTitleContainer = styled.div`
  border-bottom: 2px solid red;
`;

export const TableContainer = styled.table`
  display: flex;
  flex-direction: column;
  width 100%;
  align-items: left;
  box-sizing: border-box;
`;
export const TableRow = styled.tr`
  display: flex;
  width 100%;
  align-items: left;
  white-space:wrap;
`;

export const TableCellHeader = styled.td`
  display: flex;
  flex: 1;
  padding: 10px;
  align-items: left;
  @media (max-width: 550px) {
    flex-direction: column-reverse;
  }
`;

export const TableCellHeaderSmall = styled.td`
  display: flex;
  flex: 1;
  padding: 10px;
  align-items: left;
  display: flex;
  align-items: flex-end;
  text-transform: capitalize;
`;

export const TableCell = styled(TableCellHeader)`
  display: 'flex';
  flexDirection: 'row';
  justifyContent: 'space-between';
  vertical-align: middle;
`;

export const MainTitleTable = styled.h2`
  color: #717171;
  text-transform: uppercase;
  margin: 0;
`;

export const CartButton = styled.button`
  background-color: rgb(255, 90, 95);
  border: 0;
  color: inherit;
  cursor: pointer;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  padding: 10px 20px;
  border-radius: 2px;
  color: white;
  font-weight: 700;
  width: 200px;
  align-self: flex-end;
  @media (max-width: 768px) {
    width: auto;
  }
`;

export const CartButtonDisabled = styled(CartButton)`
  background-color: lightgrey;
  cursor: unset;
`;

export const PriceTitle = styled(EventDescriptionTitle)`
  font-size: 20px;
  margin: 0;
  text-transform: uppercase;
  padding: 30px 20px 0;
  padding: 30px 0 5px;
`;

export const NumberGuestSelect = styled.select`
  margin-right: 10px;
  @media (max-width: 550px) {
    width: 60px;
    margin: 10px 0 0;
`;

export const ProductPriceContainer = styled.div`
  display: inline-block !important;
  max-width: 100% !important;
`;

export const ProductPriceWrapper = styled.span`
  overflow-wrap: break-word !important;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 1.28571em !important;
  color: rgb(72, 72, 72) !important;
  margin: 0px !important;
`;

export const ProductPriceText = styled.span`
  font-size: 18px !important;
`;

export const ProductPriceLabelWrapper = styled.span`
  white-space: nowrap !important;
  margin-left: 2px;
`;

export const ProductPriceLabel = styled.span`
  overflow-wrap: break-word !important;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  line-height: 1.33333em !important;
  color: rgb(72, 72, 72) !important;
  margin: 0px !important;
`;

export const CautionMessageContainer = styled.div`
  height: 20px;
  display: flex;
  justify-content: flex-end;
`;

export const CautionMessage = styled.p`
  align-self: flex-end;
  margin: 0 20px;
  font-size: 13px;
  color: rgb(255, 90, 95);
`;
