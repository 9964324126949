import {
  takeLatest, put, call, select,
} from 'redux-saga/effects';
import {
  FETCH_CONFIG_APP,
  FETCH_CONFIG_APP_ERROR,
} from './actionTypes';
import * as api from './api';
import {
  setConfigApp,
} from './actions';
import { selectLocale } from '../language/selectors';
import { initialState } from './configSlice';

function* fetchConfigApp() {
  try {
    yield put(setConfigApp({
      data: [],
      status: 'loading',
      error: null,
    }));
    const locale = yield select(selectLocale);
    const response = yield call(api.getConfigApp, {
      params: {
        locale,
      },
    });
    yield put(setConfigApp(
      {
        data: JSON.parse(JSON.stringify(response.data)),
        status: 'succeeded',
        error: null,
      },
    ));
  } catch (e) {
    yield put({ type: FETCH_CONFIG_APP_ERROR, e });
  }
}

export function* watchFetchConfigApp() {
  yield takeLatest(FETCH_CONFIG_APP, fetchConfigApp);
}
