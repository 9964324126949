import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Typography } from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import {
  colFlex,
  flexBetween,
  fullWidthFlex,
  rowFlex,
} from "Styles/themes/commonStyles";
import { BigFooterLink, FooterLink, Heading } from "./styles";
import Copyright from "./Copyright";
import logo from "Assets/images/logobn-1.png";
import { useUIContext } from "../../../context/UIContext";

const useStyles = makeStyles((theme) => ({
  linksColumn: {
    display: "flex",
    flexDirection: "column",
    "&:not(:last-child)": {
      paddingBottom: "24px",
      marginBottom: "24px",
      borderBottom: `1px solid ${theme.palette.primary.main}`,
    },
    [theme.breakpoints.up("md")]: {
      paddingBottom: "0!important",
      marginBottom: "0!important",
      borderBottom: "0!important",
    },
  },
  linksList: {
    display: "block",
    flexWrap: "wrap",
    listStyle: "none",
    margin: 0,
    padding: 0,
    [theme.breakpoints.between("sm", "md")]: {
      display: "flex",
      flexWrap: "wrap",
      marginLeft: "-12px",
      marginRight: "-12px",
    },
  },
  linksListItem: {
    display: "list-item",
    marginTop: "12px",
    [theme.breakpoints.between("sm", "md")]: {
      flexBasis: "33.3333%",
      paddingLeft: "12px",
      paddingRight: "12px",
    },
  },
}));

function Footer() {
  const theme = useTheme();
  const classes = useStyles();
  const { isHomePage } = useUIContext();

  return (
    <Box
      sx={{
        ...fullWidthFlex,
        backgroundColor: (theme) => theme.palette.primary.light,
      }}
    >
      <Container
        disableGutters
        id="footer"
        maxWidth="lg"
        sx={{
          px: { xxs: "24px", sm: "40px" },
          pt: { xxs: "24px", sm: "40px" },
        }}
      >
        <Box
          sx={{
            pb: { xxs: "24px", sm: "40px" },
            ...colFlex,
            [theme.breakpoints.up("md")]: {
              ...flexBetween,
              ...rowFlex,
              borderBottom: `1px solid ${theme.palette.primary.main}`,
            },
          }}
        >
          <Box
            sx={{
              [theme.breakpoints.up("md")]: {
                width: "30%",
              },
            }}
            className={classes.linksColumn}
          >
            <Box
              sx={{
                ...rowFlex,
                [theme.breakpoints.up("md")]: {
                  ...colFlex,
                },
              }}
            >
              <Box
                sx={{
                  mt: "16px",
                  mr: "60px",
                  [theme.breakpoints.up("md")]: {
                    m: 0,
                  },
                }}
              >
                <Box
                  sx={{
                    mb: "24px",
                  }}
                >
                  <BigFooterLink href="#">FAQ’s</BigFooterLink>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      [theme.breakpoints.up("md")]: {
                        mt: 2,
                      },
                    }}
                    variant="body2"
                  >
                    <strong>Atenció telefònica:</strong>
                    <br />
                    Dilluns a divendres
                    <br />
                    Del 01/05 al 14/10 : de 9h a 17 h.
                    <br />
                    Del 15/10 al 30/04: de 9h a 14 h.
                  </Typography>
                </Box>
                <Box
                  component="img"
                  sx={{
                    my: "24px",
                    width: 150,
                    maxWidth: { s: 150, md: 150 },
                    [theme.breakpoints.up("md")]: {
                      width: "23%",
                    },
                  }}
                  alt="Refusonline.com - Central de reserves per refugis de muntanya"
                  src={logo}
                />
                <Typography variant="body2">
                  Av. Pas d'Arró,40
                  <br />
                  Vielha 25530 - Lleida (España)
                </Typography>
                <Typography variant="body2">+ 34 973 641 681</Typography>
                <Typography variant="body2">info@refusonline.com</Typography>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              [theme.breakpoints.up("md")]: {
                width: "23%",
              },
            }}
            className={classes.linksColumn}
          >
            <Heading>Asistència</Heading>
            <ul className={classes.linksList}>
              <li className={classes.linksListItem}>
                <FooterLink href="/help/cancelation/">
                  Opcions de cancel·lació
                </FooterLink>
              </li>
              <li className={classes.linksListItem}>
                <FooterLink
                  href="https://passaran.refusonline.com/"
                  target="_blank"
                >
                  Recomanacions i precaucions
                </FooterLink>
              </li>
            </ul>
          </Box>
          <Box
            sx={{
              [theme.breakpoints.up("md")]: {
                width: "23%",
              },
            }}
            className={classes.linksColumn}
          >
            <Heading>Rutes</Heading>
            <ul className={classes.linksList}>
              <li className={classes.linksListItem}>
                <FooterLink
                  href="https://carrosdefoc.refusonline.com/"
                  target="_blank"
                >
                  Carros de Foc
                </FooterLink>
              </li>
              <li className={classes.linksListItem}>
                <FooterLink
                  href="https://passaran.refusonline.com/"
                  target="_blank"
                >
                  PassAran
                </FooterLink>
              </li>
              <li className={classes.linksListItem}>
                <FooterLink href="https://caminreiau.cat/" target="_blank">
                  Camin Reiau
                </FooterLink>
              </li>
            </ul>
          </Box>
          <Box
            sx={{
              [theme.breakpoints.up("md")]: {
                width: "23%",
              },
            }}
            className={classes.linksColumn}
          >
            <Heading>Méteo</Heading>
            <ul className={classes.linksList}>
              <li className={classes.linksListItem}>
                <FooterLink href="http://www.meteo.cat/" target="_blank">
                  Predicció Meteocat
                </FooterLink>
              </li>
              <li className={classes.linksListItem}>
                <FooterLink
                  href="http://france.lachainemeteo.com/meteo-france/previsions-meteo-france-0.php"
                  target="_blank"
                >
                  La Chaine Météo
                </FooterLink>
              </li>
              <li className={classes.linksListItem}>
                <FooterLink
                  href="http://www.icgc.cat/Ciutada/Explora-Catalunya/Allaus/Butlleti-de-Perill-d-Allaus-BPA"
                  target="_blank"
                >
                  Butlletí de Perill d'Allaus
                </FooterLink>
              </li>
              <li className={classes.linksListItem}>
                <FooterLink
                  href="http://www.lauegi.conselharan.org/"
                  target="_blank"
                >
                  Lauegi D'Aran
                </FooterLink>
              </li>
              <li className={classes.linksListItem}>
                <FooterLink
                  href="http://france.lachainemeteo.com/meteo-france/previsions-meteo-france-0.php"
                  target="_blank"
                >
                  Unisys (previsió a 10 dies)
                </FooterLink>
              </li>
              <li className={classes.linksListItem}>
                <FooterLink
                  href="http://www.snow-forecast.com/"
                  target="_blank"
                >
                  Neu a les estacions d'esquí
                </FooterLink>
              </li>
            </ul>
          </Box>
          <Box
            sx={{
              [theme.breakpoints.up("md")]: {
                width: "23%",
              },
            }}
            className={classes.linksColumn}
          >
            <Heading>Social Media</Heading>
            <Box
              sx={{
                ...rowFlex,
                [theme.breakpoints.up("md")]: {
                  ...colFlex,
                },
                mt: "16px",
              }}
            >
              <FooterLink
                href="https://www.facebook.com/carrosdefoc.oficial"
                target="_blank"
              >
                <i className="fab fa-facebook-f">
                  <span style={{ marginLeft: "10px" }}>Facebook</span>
                </i>
              </FooterLink>
              <FooterLink
                href="https://www.instagram.com/carrosdefoc.oficial/"
                target="_blank"
              >
                <i className="fab fa-instagram">
                  <span style={{ marginLeft: "10px" }}>Instagram</span>
                </i>
              </FooterLink>
            </Box>
          </Box>
        </Box>
        <Copyright />
      </Container>
    </Box>
  );
}

export default Footer;
