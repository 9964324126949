import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import "react-dates/initialize";
import { DateRangePicker, isInclusivelyAfterDay } from "react-dates";
import moment from "moment";
import { useSearchContext } from "../../context/SearchContext";
import { useUIContext } from "../../context/UIContext";
import { ArrowIconLabel } from "./styles";
import { availabilityService } from "../../modules/Availability/AvailabilityService";
import useModal from "../../hooks/useModal";
import { updateSearchFilters } from "../../store/modules/search/actions";
import "react-dates/lib/css/_datepicker.css";

function CalendarRangePicker({ displayFormat, handleChange }) {
  const dispatch = useDispatch();
  const {
    setIsNewSearch,
    setIsLoadingSearch,
    isLoadingSearch,
    checkIn,
    setCheckIn,
    checkOut,
    setCheckOut,
    guests,
    setGuests,
    pickupSites,
    forfaits,
    agencyCodeId,
    doSearch,
    startAllowedDate,
    endAllowedDate,
    startDate,
    endDate,
    searchFilters,
  } = useSearchContext();

  const { isMobile, isMedium } = useUIContext();
  const firstRender = useRef(true);

  const { minimumNights, maximumNights } = availabilityService.nightRange();

  const [allowedDates, setAllowedDates] = useState({
    start: startAllowedDate,
    end: endAllowedDate,
  });

  const [pickedPeople, setPickedPeople] = useState(guests);
  const [pickedForfaits, setPickedForfaits] = useState([]);
  const [pickedSite, setPickedSite] = useState(null);
  const [pickedDate, setPickedDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);
  const [isShowingForfaitsMenu, toggleForfaitsMenu] = useModal();
  const [isShowingRecollidaMenu, toggleRecollidaMenu] = useModal();

  const isOutsideRange = (day) => {
    if (checkIn && focusedInput === "endDate") {
      const maximumEndDate = checkIn.clone().add(maximumNights + 1, "days");
      return (
        !isInclusivelyAfterDay(day, allowedDates.start) ||
        isInclusivelyAfterDay(day, maximumEndDate)
      );
    }

    if (checkOut && focusedInput === "startDate") {
      return (
        !isInclusivelyAfterDay(day, allowedDates.start) ||
        isInclusivelyAfterDay(day, allowedDates.end.clone().add(1, "day"))
      );
    }

    return (
      !isInclusivelyAfterDay(day, checkIn) ||
      moment(day).isAfter(allowedDates.end.add(1, "day"))
    );
  };

  const onFocusChange = (focused) => {
    if (isShowingRecollidaMenu) toggleRecollidaMenu();
    if (isShowingForfaitsMenu) toggleForfaitsMenu();

    setFocusedInput(focused);
  };

  function OnForfaitsClicked() {
    if (isShowingRecollidaMenu) toggleRecollidaMenu();
    toggleForfaitsMenu();
  }

  function OnRecollidaClicked() {
    if (isShowingForfaitsMenu) toggleForfaitsMenu();
    toggleRecollidaMenu();
  }

  const onDatesChange = ({ startDate, endDate }) => {
    setIsNewSearch(true);
    const time = moment("12:00", "HH:mm");
    if (startDate !== null) {
      startDate.set({
        hour: time.get("hour"),
        minute: time.get("minute"),
        second: time.get("second"),
      });
    }

    if (endDate !== null) {
      endDate.set({
        hour: time.get("hour"),
        minute: time.get("minute"),
        second: time.get("second"),
      });
    }

    const start = moment(startDate.toDate());
    const end = endDate ? moment(endDate.toDate()) : checkOut;

    setCheckIn(start);
    setCheckOut(end);

    if (focusedInput !== "startDate") {
      dispatch(
        updateSearchFilters({
          filters: {
            startDate,
            endDate,
          },
        })
      );
      handleChange();
    }
  };

  return (
    <DateRangePicker
      focusedInput={focusedInput}
      startDate={checkIn}
      startDateId={checkIn.toISOString()}
      endDate={checkOut}
      endDateId={checkOut.toISOString()}
      onDatesChange={onDatesChange}
      onFocusChange={onFocusChange}
      displayFormat={displayFormat}
      isOutsideRange={isOutsideRange}
      orientation="vertical"
      verticalHeight={568}
      minimumNights={minimumNights}
      customArrowIcon={<ArrowIconLabel>-</ArrowIconLabel>}
    />
  );
}

export default CalendarRangePicker;
