import { Navigate, useRoutes } from "react-router-dom";

import ErrorPage from "Screens/ErrorPage";
import RefugeDetailScreen from "Screens/RefugeDetailScreen/RefugeDetailScreen";
import LandingMain from "Screens/LandingMain";
import Root from "Screens/Root";
import CheckoutGuardRoute from "Screens/Checkout/CheckoutGuardRoute";
import NoOrderPage from "Screens/Checkout/NoOrderPage";
import ContactPage from "Screens/Contact/ContactPage";
import CancellationPage from "./screens/Help/Pages/CancellationPage";
import CheckoutSuccess from "./screens/Checkout/CheckoutSuccess/CheckoutSuccess";
import CheckoutFailPayment from "./screens/Checkout/CheckoutFailPayment/CheckoutFailPayment";

const router = () => {
  const routes = [
    {
      path: "/",
      element: <Root />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: "",
          element: <LandingMain screen="home" />,
        },
        {
          path: "refuge/:slug",
          element: <RefugeDetailScreen screen="place" />,
        },
        {
          path: "checkout",
          element: <CheckoutGuardRoute />,
        },
        {
          path: "checkout/ok",
          element: <CheckoutSuccess />,
        },
        {
          path: "checkout/ko",
          element: <CheckoutFailPayment />,
        },
        {
          path: "no-order",
          element: <NoOrderPage screen="noOrder" />,
        },
        {
          path: "contact",
          element: <ContactPage screen="contact" />,
        },
        {
          path: "help/cancelation",
          element: <CancellationPage screen="cancelation" />,
        },
        {
          path: "*",
          element: <Navigate to="/" />,
        },
      ],
    },
  ];

  return useRoutes(routes);
};

export default router;
