import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
// react icons
import { FaRegCopyright } from "react-icons/all";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { FooterTermsLink } from "./styles";
import { flexCenter, fullWidthFlex } from "../../../styles/themes/commonStyles";
import { useUIContext } from "../../../context/UIContext";
import Language from "../../Language/Language";

const footerLinks = [
  { id: 1, text: "Politica de privacitat i Avís Legal", url: "#" },
  { id: 2, text: "Termes i Condicions d'Ús", url: "#" },
];

function Copyright() {
  const theme = useTheme();
  const { t } = useTranslation("general");
  const { isMobile } = useUIContext();

  return (
    <Box
      sx={{
        ...fullWidthFlex,
        py: "24px",
        borderTop: `1px solid ${theme.palette.primary.main}`,
      }}
    >
      <Container disableGutters maxWidth="xl">
        <Box>
          <Box
            sx={{
              [theme.breakpoints.down("md")]: {
                ...flexCenter,
              },
            }}
          >
            <Typography variant="body2">
              <FaRegCopyright /> {new Date().getFullYear()} Trekking Carros de
              Foc SL
            </Typography>
          </Box>
          <Box
            sx={{
              [theme.breakpoints.down("md")]: {
                ...flexCenter,
              },
            }}
          >
            <FooterTermsLink href="#">
              {"· "}
              Politica de privacitat i Avís Legal
            </FooterTermsLink>
            <FooterTermsLink href="#">
              {" · "}
              Termes i Condicions d'Ús
            </FooterTermsLink>
          </Box>
        </Box>
        <Language />
      </Container>
    </Box>
  );
}

export default React.memo(Copyright);
