import { handleActions } from "redux-actions";
import moment from "moment";
import {
  resetSearch,
  updateIsNewSearch,
  updateLoadingSearch,
  updateSearchFilters,
} from "./actions";

export const initialState = {
  filters: {
    peopleNumber: 1,
    startDate: moment(),
    endDate: moment().add(1, "day"),
  },
  isNew: true,
  isLoading: false,
};

const refugesReducer = handleActions(
  {
    [resetSearch]: (state, { payload }) => ({
      initialState,
    }),
    [updateSearchFilters]: (state, { payload }) => ({
      ...state,
      filters: {
        ...state.filters,
        ...payload.filters,
      },
      isNew: false,
      isLoading: false,
    }),
    [updateLoadingSearch]: (state, { payload }) => ({
      ...state,
      isLoading: payload.isLoading,
    }),
    [updateIsNewSearch]: (state, { payload }) => ({
      ...state,
      isNew: payload.isNew,
    }),
  },
  initialState
);

export default refugesReducer;
