export const getStatusColor = (status) => {
  switch (status) {
    case "open":
      return {
        main: "#49a017",
        bg: "#F1F8E9",
      };
    case "closed":
      return {
        main: "#fff",
        bg: "#d0cdcd",
      };
    case "full":
      return {
        main: "#E53935",
        bg: "#FFEBEE",
      };
    case "event":
      return {
        main: "mediumseagreen",
        bg: "mediumseagreen",
      };
    case "middle":
      return {
        main: "#FDD835",
        bg: "#FFFDE7",
      };
    case "restricted":
      return {
        main: "#FF9800",
        bg: "#FFF3E0",
      };
    default:
      return {
        main: "#909496",
        bg: "#ccc",
      };
  }
};
