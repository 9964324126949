import { AvailabilityStatus } from "../modules/Availability/Domain/ValueObject/AvailabilityStatus";

const sortMethods = {
  none: { method: (a, b) => null },
  status: {
    method: (a, b) => {
      const statusKeys = Object.keys(AvailabilityStatus(() => {}));
      return statusKeys.indexOf(a.status) > statusKeys.indexOf(b.status)
        ? 1
        : -1;
    },
  },
  ascending: { method: (a, b) => (a.name > b.name ? 1 : -1) },
  descending: { method: (a, b) => (a.name > b.name ? -1 : 1) },
};

export { sortMethods };
