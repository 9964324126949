import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Link } from "@mui/material";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/styles";
import {
  displayOnBigMobile,
  displayOnDesktop,
  displayOnTablet,
  flexBetween,
} from "../../styles/themes/commonStyles";
import LocationSearch from "../LocationSearch/LocationSearch";
import Logo from "./Logo";
import CartBadge from "./CartBadge";
import { getCart } from "../../store/modules/order/selectors";
import { useUIContext } from "../../context/UIContext";

function Header() {
  const { page, setCartOpen } = useUIContext();
  const theme = useTheme();
  const cart = useSelector(getCart);

  const handleCartBadgeClick = () => {
    setCartOpen(true);
  };

  return (
    <Box
      sx={{
        borderBottom: "1px solid #ddd",
      }}
    >
      <Container disableGutters maxWidth={page !== "home" ? "lg" : false}>
        <Box
          sx={{
            ...flexBetween,
            alignItems: "center",
            height: theme.global.headerHeight,
            px: { xxs: "16px", sm: "40px", md: "40px" },
          }}
        >
          <Box
            sx={{
              ...flexBetween,
              ...displayOnBigMobile,
            }}
          >
            <Link
              href="/"
              sx={{
                mr: "8px",
              }}
            >
              <Logo />
            </Link>
          </Box>
          <Box>
            <LocationSearch />
          </Box>
          <Box
            sx={{
              ...flexBetween,
              alignItems: "center",
            }}
          >
            <Box sx={{ ...displayOnDesktop }}>
              <Link
                href="/"
                sx={{
                  mr: "8px",
                }}
              >
                {" "}
                Tens algun dubte?
              </Link>
            </Box>
            <Box sx={{ ...displayOnTablet }}>
              <CartBadge
                handleClick={handleCartBadgeClick}
                badgeContent={cart && cart.length}
              />
            </Box>
          </Box>
          {/* <Box sx={displayOnLargeDesktop}>
                <MobileSearch />
              </Box> */}
        </Box>
      </Container>
    </Box>
  );
}

export default React.memo(Header);
