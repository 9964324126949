/** Constants */
export const HeaderMinHeight = 90;
export const DrawerWidth = 600;
export const MobileFooterMinHeight = 73;
export const LayoutGeneralPaddingXL = 80;
export const LayoutGeneralPaddingSM = 40;
export const LayoutGeneralPadding = 24;

/** Flexbox styles */
export const displayOnDesktop = {
  display: { xxs: "none", s: "none", sm: "flex" },
};
export const displayOnMobile = {
  display: { xxs: "flex", s: "flex", sm: "none" },
};
export const displayOnBigMobile = {
  display: { xxs: "none", xs: "flex" },
};
export const displayOnTablet = {
  display: { xxs: "none", s: "flex", sm: "flex" },
};
export const displayOnLargeDesktop = {
  display: {
    xxs: "none",
    md: "flex",
  },
};

export const flexContainer = {
  display: "flex",
};
export const flexBetween = {
  display: "flex",
  justifyContent: "space-between",
};

export const flexBetweenCenter = {
  display: "flex",
  justifyContent: { xxs: "center", md: "space-between" },
  alignItems: "center",
};

export const flexAround = {
  display: "flex",
  justifyContent: "space-around",
};

export const footerLayout = {
  display: "flex",
  flexDirection: { sx: "column" },
  justifyContent: { xxs: "center", md: "space-between" },
  alignItems: "center",
};

export const flexCenter = {
  display: "flex",
  justifyContent: "center",
};

export const flexStart = {
  display: "flex",
  justifyContent: "flex-start",
};

export const flexCenterAligned = {
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
};

export const flexEnd = {
  display: "flex",
  justifyContent: "flex-end",
};

export const fullWidthFlex = {
  display: "flex",
  width: "100%",
};

export const justifyCenter = { display: "flex", justifyContent: "center" };

export const colFlex = {
  display: "flex",
  flexDirection: "column",
};

export const rowFlex = {
  display: "flex",
  flexDirection: "row",
};

export const fixedBottom = {
  position: "absolute",
  bottom: 100,
  width: "100%",
};

/** Custom carousel styles */

export const carouselDot = {
  color: "#fff",
  backgroundColor: "#000",
  opacity: 0.5,
  borderRadius: 10,
  p: 1,
  minWidth: "auto",
};

export const fixedIcon = {
  position: "absolute",
  right: 10,
  top: 10,
  zIndex: 10,
};

export const carouselImage = {
  height: 275,
  display: "block",
  overflow: "hidden",
  width: "100%",
  borderRadius: 3,
};

export const roundWhiteIconShape = {
  display: "inline-flex",
  backgroundColor: "white",
  height: 40,
  width: 40,
  justifyContent: "center",
  borderRadius: "50%",
  border: "1px solid rgba(0, 0, 0, 0.08)",
  alignItems: "center",
  backgroundClip: "padding-box",
  boxShadow:
    "0 0 0 1px transparent, 0 0 0 4px transparent, 0 2px 4px rgba(0,0,0,0.18)",
  transition:
    "-ms-transform 0.25s ease 0s, -webkit-transform 0.25s ease 0s, transform 0.25s ease 0s",
};
