import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import Box from "@mui/material/Box";
import LocationCards from "../components/SearchFilter/LocationCards";
import { fetchRefuges } from "../store/modules/refuges/actions";
import Map from "../components/Map/Map";
import LocationCardsDrawer from "../components/SearchFilter/LocationCardsDrawer";
import { useUIContext } from "../context/UIContext";
import { useSearchContext } from "../context/SearchContext";
import BubblesLoading from "../components/Shared/BubblesLoading";
import InputSearchBar from "../components/LocationSearch/InputSearchBar";
import { MapProvider } from "../context/MapContext";
import { usePage } from "../hooks/usePage";
import { updateSearchFilters } from "../store/modules/search/actions";

function LandingMain({ screen }) {
  usePage(screen);

  const dispatch = useDispatch();

  const { isMobile, setPage } = useUIContext();
  const { setIsLoadingSearch, isLoadingSearch, startDate, endDate } =
    useSearchContext();

  useEffect(() => {
    updateSearchFilters({
      filters: {
        startDate,
        endDate,
      },
    });

    setIsLoadingSearch(true);
    try {
      async function fetchData() {
        await new Promise((resolve, reject) => {
          dispatch(fetchRefuges({ onComplete: resolve, onError: reject }));
        });
      }

      fetchData().then((r) => {});
    } catch (error) {
      setIsLoadingSearch(false);
      // handle any rejections/errors
      console.log(error);
    }
  }, []);

  return (
    <MapProvider>
      <Box
        sx={{
          display: "flex",
          height: "calc(100vh - 80px)",
        }}
      >
        {isMobile ? (
          <LocationCardsDrawer />
        ) : (
          <Box
            sx={{
              width: "55%",
              px: { xxs: "16px", sm: "40px" },
              height: "calc(100vh - 5rem)",
              overflowY: "scroll",
              paddingBottom: "32px",
              ...(isMobile && {
                width: "100vw",
                zIndex: 1,
                mt: "51vh",
              }),
            }}
          >
            <InputSearchBar />
            <LocationCards />
          </Box>
        )}
        <Box
          sx={{
            width: "45%",
            ...(isMobile && {
              width: "100vw",
              position: "absolute",
              left: 0,
              height: "85vh",
              zIndex: 0,
            }),
          }}
        >
          {isLoadingSearch ? (
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "10vh",
              }}
            >
              <BubblesLoading />
            </Box>
          ) : (
            <Map />
          )}
        </Box>
      </Box>
    </MapProvider>
  );
}

export default React.memo(LandingMain);
