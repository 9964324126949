import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Global } from "@emotion/react";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { grey } from "@mui/material/colors";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { useUIContext } from "../../context/UIContext";
import Footer from "../Layout/Footer/Footer";
import { H3 } from "../../styles/Headings.styled";
import { LinearProgress } from "@mui/material";
import { useSearchContext } from "../../context/SearchContext";
import { flexCenter } from "../../styles/themes/commonStyles";
import { useTheme } from "@mui/styles";
import InputSearchBar from "../LocationSearch/InputSearchBar";
import LocationCards from "./LocationCards";
import useScrollGoingDown from "../../hooks/useScrollGoingDown";
import { useDispatch } from "react-redux";
import { setMobileBottomNavShow } from "../../store/modules/ui/actions";
import { useTranslation } from "react-i18next";

const drawerBleeding = 52;

const Root = styled("div")(({ theme }) => ({
  height: "100%",
  backgroundColor:
    theme.palette.mode === "light"
      ? grey[100]
      : theme.palette.background.default,
}));

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "light" ? "#fff" : grey[800],
}));

const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === "light" ? grey[500] : grey[900],
  borderRadius: 3,
  position: "absolute",
  top: 8,
  left: "calc(50% - 15px)",
}));

function LocationCardsDrawer(props) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { t } = useTranslation("search");
  const { window } = props;
  const { mobileLocationsDrawerOpen, setMobileLocationsDrawerOpen } =
    useUIContext();
  const { isLoadingSearch, places, availablePlacesCount } = useSearchContext();

  const toggleDrawer = (newOpen) => () => {
    setMobileLocationsDrawerOpen(newOpen);
  };

  // This is used only for the example
  const container =
    window !== undefined ? () => window().document.body : undefined;

  const styles = {
    line2: {
      borderTop: "0.5px solid rgb(230, 230, 230)",
      margin: "30px 0px",
    },
  };
  const [elRefs, setElRefs] = React.useState([]);
  React.useEffect(() => {
    setElRefs((refs) =>
      Array(places?.length)
        .fill()
        .map((_, i) => refs[i] || React.createRef())
    );
  }, [places]);

  const [scrollRef, goingDown, handleScroll] = useScrollGoingDown();
  useEffect(() => {
    dispatch(setMobileBottomNavShow(!goingDown));
  }, [goingDown]);

  return (
    <Root>
      <CssBaseline />
      <Global
        styles={{
          ".MuiDrawer-root > .MuiPaper-root": {
            height: `calc(90% - ${drawerBleeding}px)`,
            overflow: "visible",
          },
        }}
      />
      <SwipeableDrawer
        container={container}
        anchor="bottom"
        open={mobileLocationsDrawerOpen}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <StyledBox
          sx={{
            position: "absolute",
            top: -drawerBleeding,
            borderTopLeftRadius: 24,
            borderTopRightRadius: 24,
            visibility: "visible",
            right: 0,
            left: 0,
            pb: "12px",
          }}
        >
          <Puller />
          <Box
            sx={{
              ...flexCenter,
              mt: "24px",
              px: "24px",
            }}
          >
            {isLoadingSearch ? (
              <H3>
                {t("search.loading_refuges")}
                <LinearProgress />
              </H3>
            ) : (
              <H3>{t("search.results", { availablePlacesCount })}</H3>
            )}
          </Box>
        </StyledBox>
        <StyledBox
          ref={scrollRef}
          onScroll={handleScroll}
          sx={{
            height: "100%",
            overflow: "scroll",
          }}
        >
          <Box
            sx={{
              px: "24px",
              mb: "24px",
            }}
          >
            <InputSearchBar />
            <LocationCards places={places} />
          </Box>
          <Box
            sx={{
              pb: `${theme.global.mobileFooterHeight}px`,
            }}
          >
            <Footer />
          </Box>
        </StyledBox>
      </SwipeableDrawer>
    </Root>
  );
}

LocationCardsDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default React.memo(LocationCardsDrawer);
