import React, { useState } from "react";
import "react-dates/initialize";
import { DateRangePicker, isInclusivelyAfterDay } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import moment from "moment";

import { useDispatch } from "react-redux";
import IconButton from "@mui/material/IconButton";
import { FaSearch } from "react-icons/fa";
import InputBase from "@mui/material/InputBase";
import { Divider } from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Button from "@mui/material/Button";
import { useConfigApp } from "../../context/ConfigAppContext";
import useModal from "../../hooks/useModal";
import { availabilityService } from "../../modules/Availability/AvailabilityService";
import { ArrowIconLabel, SearchContainer, SearchWrapper } from "./styles";
import { flexBetweenCenter } from "../../styles/themes/commonStyles";
import { fetchRefuges } from "../../store/modules/refuges/actions";
import { useSearchContext } from "../../context/SearchContext";
import { useUIContext } from "../../context/UIContext";
import { appendForfaitsToCart } from "../../store/modules/order/actions";
import { updateSearchFilters } from "../../store/modules/search/actions";

const _ = require("lodash");

function LocationSearch() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { isMobile, page } = useUIContext();
  const {
    setIsLoadingSearch,
    isLoadingSearch,
    setIsNewSearch,
    checkIn,
    setCheckIn,
    checkOut,
    setCheckOut,
    guests,
    setGuests,
    pickupSites,
    forfaits,
    agencyCodeId,
    startAllowedDate,
    endAllowedDate,
    startDate,
    endDate,
    searchFilters,
  } = useSearchContext();

  const { configs, updateConfig } = useConfigApp();

  const { minimumNights, maximumNights } = availabilityService.nightRange();

  const [allowedDates, setAllowedDates] = useState({
    start: startAllowedDate,
    end: endAllowedDate,
  });

  const [pickedForfaits, setPickedForfaits] = useState([]);
  const [pickedSite, setPickedSite] = useState(null);
  const [pickedDate, setPickedDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);
  const [isShowingForfaitsMenu, toggleForfaitsMenu] = useModal();
  const [isShowingRecollidaMenu, toggleRecollidaMenu] = useModal();

  const isOutsideRange = (day) => {
    if (checkIn && focusedInput === "endDate") {
      const maximumEndDate = checkIn.clone().add(maximumNights + 1, "days");
      return (
        !isInclusivelyAfterDay(day, allowedDates.start) ||
        isInclusivelyAfterDay(day, maximumEndDate)
      );
    }

    if (checkOut && focusedInput === "startDate") {
      return (
        !isInclusivelyAfterDay(day, allowedDates.start) ||
        isInclusivelyAfterDay(day, allowedDates.end.clone().add(1, "day"))
      );
    }

    return (
      !isInclusivelyAfterDay(day, checkIn) ||
      moment(day).isAfter(allowedDates.end.add(1, "day"))
    );
  };

  const handleFetchRefuges = () => {
    async function fetchData() {
      await new Promise((resolve, reject) => {
        dispatch(fetchRefuges({ onComplete: resolve, onError: reject }));
      });
    }

    fetchData().then((r) => {
      setIsNewSearch(false);
    });
  };

  const onFocusChange = (focused) => {
    if (isShowingRecollidaMenu) toggleRecollidaMenu();
    if (isShowingForfaitsMenu) toggleForfaitsMenu();

    setFocusedInput(focused);
  };

  function OnForfaitsClicked() {
    if (isShowingRecollidaMenu) toggleRecollidaMenu();
    toggleForfaitsMenu();
  }

  function OnRecollidaClicked() {
    if (isShowingForfaitsMenu) toggleForfaitsMenu();
    toggleRecollidaMenu();
  }

  const onDatesChange = ({ startDate, endDate }) => {
    const time = moment("12:00", "HH:mm");
    if (startDate !== null) {
      startDate.set({
        hour: time.get("hour"),
        minute: time.get("minute"),
        second: time.get("second"),
      });
    }

    if (endDate !== null) {
      endDate.set({
        hour: time.get("hour"),
        minute: time.get("minute"),
        second: time.get("second"),
      });
    }

    const start = moment(startDate.toDate());
    const end = endDate ? moment(endDate.toDate()) : checkOut;

    setCheckIn(start);
    setCheckOut(end);

    if (focusedInput !== "startDate") {
      dispatch(
        updateSearchFilters({
          filters: {
            startDate: start,
            endDate: end,
          },
        })
      );
    }
  };

  const onPeopleChanged = (event) => {
    const people = parseInt(event.target.value, 10) || 1;
    setGuests(people);
    dispatch(updateSearchFilters({ filters: { peopleNumber: people } }));
  };

  const onSaveForfaitsHandler = (forfaits) => {
    setPickedForfaits(forfaits);
    toggleForfaitsMenu();
  };
  const onSavePickupHandler = (date, site) => {
    setPickedDate(date);
    setPickedSite(site);
    dispatch(
      appendForfaitsToCart({
        index: 0,
        refuge: {
          products: pickedForfaits.map((product) =>
            Object.assign(product, {
              agency_code_id: agencyCodeId,
              product_name: product.name,
              quantity: product.count,
              product_id: product.id,
              membership_id: product.membershipId,
              rang_edat_id: product.ageRangeId,
              price: product.price,
              deposit: product.deposit,
              membership_name: product.membershipName,
              rang_edat_name: product.ageRangeName,
            })
          ),
          site,
          from: date,
          to: date,
        },
      })
    );

    toggleRecollidaMenu();
  };

  const useStyles = makeStyles((theme) => ({
    hostInput: {
      width: "35px !important",
      "&.MuiInputBase-root .MuiInputBase-input": {
        padding: "0 !important",
      },
    },
  }));

  const classes = useStyles();

  return (
    <>
      {page && page === "place" ? (
        <Button
          href="/"
          sx={{
            minWidth: page && page === "refuge" ? "185px" : "285px",
            height: "48px",
            justifyContent: "space-between",
            px: "8px",
            display: "inline-flex",
            alignItems: "center",
            border: "1px solid #ddd",
            borderRadius: 10,
            fontWeight: "bold",
          }}
        >
          <Typography
            variant="small"
            sx={{
              px: "16px",
            }}
          >
            altres refugis
          </Typography>
          <IconButton
            sx={(theme) => ({
              "&:hover": { backgroundColor: theme.palette.secondary.main },
              width: "32px",
              height: "32px",
              pt: "12px",
              pb: "10px",
              pr: "10px",
              pl: "10px",
              borderRadius: "50%",
              backgroundColor: theme.palette.secondary.main,
              color: theme.palette.white,
            })}
          >
            <FaSearch />
          </IconButton>
        </Button>
      ) : (
        <SearchContainer
          sx={{
            minWidth: page && page === "refuge" ? "185px" : "285px",
          }}
        >
          <SearchWrapper>
            {isLoadingSearch ? (
              <Skeleton
                animation="wave"
                sx={{
                  width: "92px",
                  height: "20px",
                }}
              />
            ) : (
              <Box
                sx={{
                  ...flexBetweenCenter,
                  [theme.breakpoints.up("xs")]: {
                    padding: "14px 32px",
                  },
                  padding: "14px 16px",
                }}
              >
                <InputBase
                  className={classes.hostInput}
                  onChange={onPeopleChanged}
                  variant="standard"
                  id="standard-adornment-weight"
                  type="number"
                  min="1"
                  max="100"
                  defaultValue={guests}
                  aria-describedby="input-number-hosts"
                  sx={{
                    width: "35px",
                  }}
                />
                <Typography>
                  hoste
                  {(guests > 1 || guests === 0) && "s"}
                </Typography>
              </Box>
            )}
            <Divider orientation="vertical" variant="middle" flexItem />
            {isLoadingSearch ? (
              <Skeleton
                animation="wave"
                sx={{
                  width: "130px",
                  height: "20px",
                }}
              />
            ) : (
              <Box
                className="searchDateRangePickerDesktop"
                sx={{
                  [theme.breakpoints.up("xs")]: {
                    padding: "0 24px",
                    mt: "10px",
                  },
                  padding: "0 10px",
                  marginTop: "10px",
                }}
              >
                <DateRangePicker
                  focusedInput={focusedInput}
                  startDate={checkIn}
                  startDateId={checkIn.toISOString()}
                  endDate={checkOut}
                  endDateId={checkOut.toISOString()}
                  onDatesChange={onDatesChange}
                  onFocusChange={onFocusChange}
                  displayFormat="D MMM[.]"
                  isOutsideRange={isOutsideRange}
                  orientation={isMobile ? "vertical" : "horizontal"}
                  verticalHeight={568}
                  minimumNights={minimumNights}
                  customArrowIcon={<ArrowIconLabel>-</ArrowIconLabel>}
                />
              </Box>
            )}
            <Box
              sx={{
                paddingRight: "9px",
              }}
            >
              <IconButton
                disabled={isLoadingSearch}
                onClick={handleFetchRefuges}
                sx={(theme) => ({
                  "&:hover": { backgroundColor: theme.palette.secondary.main },
                  width: "32px",
                  height: "32px",
                  marginLeft: "-6px",
                  paddingRight: "9px",
                  borderRadius: "50%",
                  backgroundColor: theme.palette.secondary.main,
                  color: theme.palette.white,
                })}
              >
                <FaSearch />
              </IconButton>
            </Box>
          </SearchWrapper>
        </SearchContainer>
      )}
    </>
  );
}

export default LocationSearch;
