const supportedLanguages = ["en", "es", "fr", "ca"];

const resources = supportedLanguages.reduce((acc, lang) => {
  try {
    // Manually list the filenames in the language directory
    const filenames = [
      "bookit",
      "cart",
      "errors",
      "general",
      "no_order",
      "place",
      "search",
    ];
    acc[lang] = filenames.reduce((translations, filename) => {
      try {
        translations[filename] = require(`./locales/${lang}/${filename}.json`);
      } catch (error) {
        console.error(
          `Error loading translation file ${filename} for language ${lang}:`,
          error
        );
      }
      return translations;
    }, {});
  } catch (error) {
    console.error(`Error loading translations for language ${lang}:`, error);
  }
  return acc;
}, {});

export default resources;
