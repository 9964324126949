import { useSelector } from "react-redux";
import { CheckoutProvider } from "../../context/CheckoutContext";
import CheckoutPage from "./Checkout";
import { Navigate } from "react-router-dom";
import { getCart } from "../../store/modules/order/selectors";

const CheckoutGuardRoute = () => {
  const cart = useSelector(getCart);

  if (cart.length > 0) {
    return (
      <CheckoutProvider>
        <CheckoutPage screen="checkout" />
      </CheckoutProvider>
    );
  } else {
    return <Navigate to="/no-order" />;
  }
};

export default CheckoutGuardRoute;
