export default {
  formId: 'checkoutForm',
  formField: {
    nif: {
      name: 'nif',
      label: 'DNI / Passport*',
      requiredErrorMsg: 'DNI / Passport number is required by legal obligation (Real Decreto 933/2021)',
    },
    firstName: {
      name: 'firstName',
      label: 'First name*',
      requiredErrorMsg: 'First name is required',
    },
    lastName: {
      name: 'lastName',
      label: 'Last name*',
      requiredErrorMsg: 'Last name is required',
    },
    email: {
      name: 'email',
      label: 'Email*',
      requiredErrorMsg: 'Email is required for contact',
    },
    phone: {
      name: 'phone',
      label: 'Phone* (indicar prefix · Ex: +34)',
      requiredErrorMsg: 'Phone is required for direct contact and your security',
    },
    address1: {
      name: 'address1',
      label: 'Address Line 1*',
      requiredErrorMsg: 'Address Line 1 is required',
    },
    address2: {
      name: 'address2',
      label: 'Address Line 2',
    },
    city: {
      name: 'city',
      label: 'City*',
      requiredErrorMsg: 'City is required',
    },
    state: {
      name: 'state',
      label: 'State/Province/Region',
    },
    zipcode: {
      name: 'zipcode',
      label: 'Zipcode*',
      requiredErrorMsg: 'Zipcode is required',
      invalidErrorMsg: 'Zipcode is not valid (e.g. 70000)',
    },
    country: {
      name: 'country',
      label: 'Country*',
      requiredErrorMsg: 'Country is required',
    },
    sortida: {
      name: 'sortida',
      label: 'Lloc de sortida a peu?',
    },
    comments: {
      name: 'comments',
      label: 'Al·lèrgies, intoleràncies, vegetarians, etc / Comentaris (opcional)',
    },
    acceptDataAcceptance: {
      name: 'acceptDataAcceptance',
      label: 'Autoritzo el tractament de les dades personals facilitades per processar la meva comanda i les finalitats descrites a la política de privacitat.*',
    },
  },
};
