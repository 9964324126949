import { useDispatch, useSelector } from "react-redux";
import {
  selectRefugeBySlug,
  selectServices,
} from "../../store/modules/refuges/selectors";
import { useState } from "react";
import { GetRefugeUseCase } from "../../modules/Availability/Application/GetRefugeUseCase";
import { GetRefugeProductsUseCase } from "../../modules/Availability/Application/GetRefugeProductsUseCase";

export const useRefugeDetailScreen = (slug) => {
  const [isLoadingSearch, setIsLoadingSearch] = useState(true);
  const [isLoadingProducts, setIsLoadingProducts] = useState(false);
  const refuge = useSelector(selectRefugeBySlug(slug));
  const products = useSelector(selectServices);

  const dispatch = useDispatch();
  const getRefugeUseCase = new GetRefugeUseCase(dispatch);
  const getRefugeProductsUseCase = new GetRefugeProductsUseCase(dispatch);

  const fetchRefuge = async (slug) => {
    setIsLoadingSearch(true);
    const { result, error } = await getRefugeUseCase.execute(slug);
    const { site } = result;
    if (!error) {
      setIsLoadingProducts(true);
      const { result, error } = await getRefugeProductsUseCase.execute(site.id);
      setIsLoadingProducts(false);
    }
    setIsLoadingSearch(false);
  };

  return {
    isLoadingSearch,
    setIsLoadingSearch,
    fetchRefuge,
    refuge,
    products,
    isLoadingProducts,
    setIsLoadingProducts,
  };
};
