import * as React from "react";
import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import Status from "Components/Refuge/Status";
import { useTranslation } from "react-i18next";
import { Skeleton } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import { makeStyles } from "@mui/styles";
import CardMedia from "@mui/material/CardMedia";
import {
  CategoryItemWithStatusWrapper,
  NameItem,
  NameItemWrapper,
  ServicesItemWrapper,
} from "./styles";
import { TextItem } from "Components/Shared/styles";
import { SkeletonCardImage } from "./SkeletonRefugeCard";
import { flexBetween } from "Styles/themes/commonStyles";
import { useMapContext } from "Context/MapContext";
import { TransparentStyledCardActionArea } from "Styles/mui/RefugeCard";

const _ = require("lodash");

function HtmlMetaItem({ site, position }) {
  const { name, url, category, images, email, phone, address, location } = site;
  return (
    <div itemProp="item" itemScope itemType="https://schema.org/LocalBusiness">
      <meta
        itemProp="name"
        content={`${category} ${name} - ${address.city} - ${address.country}`}
      />
      <meta itemProp="address" content={address.city} />
      <meta itemProp="telephone" content={phone} />
      <meta itemProp="email" content={email} />
      <meta itemProp="latitude" content={location.latitude} />
      <meta itemProp="longitude" content={location.longitude} />
      <meta itemProp="image" content={images[0]} />
      <meta itemProp="url" content={url} />
    </div>
  );
}

function CardFirstRow({ name, category, status }) {
  const { t } = useTranslation("place");
  return (
    <CategoryItemWithStatusWrapper>
      <NameItemWrapper>
        <TextItem>
          <>
            {category ? (
              _.capitalize(t(`refuge.type.${_.lowerCase(category)}`))
            ) : (
              <span>&nbsp;</span>
            )}
          </>
        </TextItem>
        <NameItem style={{ width: "100%" }}>
          {_.startCase(_.toLower(`"${name}"`)) || <Skeleton variant="text" />}
        </NameItem>
      </NameItemWrapper>
      <Status statusCode={status} />
    </CategoryItemWithStatusWrapper>
  );
}

function ContactItem({ contact }) {
  return (
    <ServicesItemWrapper style={{ marginTop: "4px" }}>
      {contact.phone}
      {contact.email && contact.phone && <span aria-hidden="true"> · </span>}
      {contact.email}
    </ServicesItemWrapper>
  );
}

function UrlItem({ url }) {
  return (
    <ServicesItemWrapper style={{ marginTop: "4px" }}>
      <span
        role="link"
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          window.open(
            `http://${url.substring(url.lastIndexOf("/") + 1)}`,
            "_blank"
          );
        }}
      >
        {url.substring(url.lastIndexOf("/") + 1)}
      </span>
    </ServicesItemWrapper>
  );
}

function CardDetailRow({ services }) {
  const { t } = useTranslation("place");

  return (
    <div>
      <TextItem>
        {services.people}
        &nbsp;
        {t("refuge.services.hosts")}
        {services.altitud && (
          <span>
            &nbsp;a &nbsp;
            {services.altitud}m d'altitud
          </span>
        )}
      </TextItem>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  rentalDiv: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  rentalInfo: {
    display: "grid",
    gap: "2px 8px",
    gridTemplateColumns: "minmax(0,1fr) max-content",
    gridTemplateRows: "unset",
  },
  rentalTitle: {
    fontSize: "16px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
      mb: 1,
    },
  },
  rentalDesc: {
    fontSize: "15px",
    color: "gray",
    marginTop: "0",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  bottomButton: {
    marginTop: "20px",
    justifyContent: "space-between",
    display: "flex",
    width: "100%",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
      mt: 1,
    },
  },
  price: {
    display: "flex",
    justifyContent: "end",
    gap: "5px",
    color: "#808080",
    fontSize: "12px",
  },
  card: {},
  mediaWrapper: {
    marginBottom: "12px",
    aspectRatio: "20 / 19",
  },
  media: {
    width: "100%",
    height: "100%",
    borderRadius: "20px",
    position: "relative",
  },
}));

function RefugeCard({ i, place }) {
  const { t } = useTranslation("place");
  const { setHoverItem } = useMapContext();
  const [imgIsLoaded, setImgIsLoaded] = useState(false);

  const handleImageLoaded = () => {
    setImgIsLoaded(true);
  };

  const classes = useStyles();
  return (
    <Card
      className={classes.card}
      onMouseEnter={(e) => {
        e.stopPropagation();
        e.preventDefault();
        setHoverItem(place);
      }}
      onMouseLeave={() => {
        setHoverItem(null);
      }}
    >
      <TransparentStyledCardActionArea
        component={RouterLink}
        to={{
          pathname: `/refuge/${place.site.slug}`,
          place,
        }}
      >
        <div
          itemProp="itemListElement"
          itemScope
          itemType="http://schema.org/ListItem"
        >
          <HtmlMetaItem site={place.site} position={i} />
          {place.site.images && place.site.images[0] ? (
            <Box className={classes.mediaWrapper}>
              <CardMedia
                component="img"
                loading="lazy"
                className={classes.media}
                onLoad={handleImageLoaded}
                image={place.site.images[0]}
                width="100%"
              />
            </Box>
          ) : (
            <SkeletonCardImage />
          )}
          <Box className={classes.rentalInfo}>
            <Box className={classes.rentalTitle}>{place.site.name}</Box>
            <Box
              sx={{
                ...flexBetween,
                alignItems: "center",
              }}
            >
              <Status statusCode={place.status} size="small" />
            </Box>
          </Box>
          <Box className={classes.rentalDesc}>
            {place.site.category && (
              <span>
                {_.capitalize(
                  t(`refuge.type.${_.lowerCase(place.site.category)}`)
                )}
                &nbsp;a&nbsp;
              </span>
            )}
            {place.site.address.city}
          </Box>
          <Box className={classes.rentalDesc}>
            {place.site.people}
            &nbsp;
            {t("refuge.services.hosts")}
            {place.site.altitud && (
              <span>
                &nbsp;a&nbsp;
                {place.site.altitud}m d'altitud
              </span>
            )}
          </Box>
        </div>
      </TransparentStyledCardActionArea>
    </Card>
  );
}

export default React.memo(RefugeCard);
