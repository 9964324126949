import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { FaBed, FaShower, FaThinkPeaks } from "react-icons/fa";
import Stack from "@mui/material/Stack";
import { Icon } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import {
  flexAround,
  flexCenter,
  flexStart,
} from "../../styles/themes/commonStyles";
import { faMattressPillow } from "@fortawesome/free-solid-svg-icons";
import SectionHeading from "../../screens/Help/dumbs/HelpSectionHeading";

function SectionAmenities({ isMobile, site }) {
  const { t } = useTranslation(["place"]);

  return (
    <Box>
      {!isMobile && <SectionHeading title={t("refuge.amenities.heading")} />}

      <Stack
        direction={isMobile ? "row" : "column"}
        spacing={isMobile ? 0 : 3}
        sx={isMobile ? flexAround : flexStart}
      >
        {site.people && (
          <Stack
            sx={isMobile ? flexCenter : flexStart}
            direction={isMobile ? "column" : "row"}
            spacing={isMobile ? 2 : 3}
          >
            <Icon>
              <FaBed size={24} />
            </Icon>
            <Typography
              variant={isMobile ? "body2" : "body"}
              color="primary.dark"
            >
              {t("refuge.amenities.capacity", { capacity: site.people })}
            </Typography>
          </Stack>
        )}
        {site.altitud && (
          <Stack
            sx={isMobile ? flexCenter : flexStart}
            direction={isMobile ? "column" : "row"}
            spacing={isMobile ? 2 : 3}
          >
            <Icon>
              <FaThinkPeaks size={24} />
            </Icon>
            <Typography
              variant={isMobile ? "body2" : "body"}
              color="primary.dark"
            >
              {t("refuge.amenities.altitude", { altitude: site.altitud })}
            </Typography>
          </Stack>
        )}
        {site.people && (
          <Stack
            sx={isMobile ? flexCenter : flexStart}
            direction={isMobile ? "column" : "row"}
            spacing={isMobile ? 2 : 3}
          >
            <FontAwesomeIcon icon={faMattressPillow} size="lg" />
            <Typography
              variant={isMobile ? "body2" : "body"}
              color="primary.dark"
            >
              {t("refuge.amenities.coixins")}
            </Typography>
          </Stack>
        )}
        {site.people && (
          <Stack
            sx={isMobile ? flexCenter : flexStart}
            direction={isMobile ? "column" : "row"}
            spacing={isMobile ? 2 : 3}
          >
            <Icon>
              <FaShower size={24} />
            </Icon>
            <Typography
              variant={isMobile ? "body2" : "body"}
              color="primary.dark"
            >
              {t("refuge.amenities.dutxa")}
            </Typography>
          </Stack>
        )}
      </Stack>
    </Box>
  );
}

export default React.memo(SectionAmenities);
