import React from "react";
import { Divider } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import useStyles from "./styles";
import { getCart } from "../../../store/modules/order/selectors";
import { orderService } from "../../../modules/Order/OrderService";
import StayInfo from "../../../components/BookIt/StayInfo";
import CartResumeCost from "../../../components/Cart/CartResumeCost";
import ContactInfo from "./ContactInfo";

function ProductList() {
  const classes = useStyles();

  const cart = useSelector(getCart);
  const cartPrice = orderService.calculateCartPrice(cart);
  const orderPrice = orderService.calculateOrderPrice(cart);

  const styles = {
    productsList: {
      display: "flex",
      flexDirection: "column",
      mb: 2,
      border: "1px solid",
      borderColor: (theme) => theme.palette.black,
      borderRadius: 2,
    },
  };

  return (
    <Box sx={styles.productsList}>
      <Typography disableGutters sx={{ p: "10px" }} variant="h6">
        El teu itinerari
      </Typography>
      <Divider orientation="horizontal" flexItem />
      <Paper elevation={0} sx={{ p: "10px" }}>
        {cart.map((item, i) => (
          <StayInfo index={i} stay={item} />
        ))}
      </Paper>
      <CartResumeCost />
      <Divider variant="horizontal" flexItem />
      <ContactInfo />
    </Box>
  );
}

export default ProductList;
