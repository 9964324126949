import { createContext, useContext, useState } from "react";
import moment from "moment";
import { AvailabilityStatus } from "../modules/Availability/Domain/ValueObject/AvailabilityStatus";
import { useTranslation } from "react-i18next";

export const StayContext = createContext({});
export const useStayContext = () => useContext(StayContext);

export function StayProvider({ children }) {
  const { t } = useTranslation(["place"]);
  const [datesFeedbackText, setDatesFeedbackText] = useState("");
  const [isBookingEnabled, setIsBookingEnabled] = useState({
    status: false,
    msg: "",
  });
  const [isLoadingAddToCart, setIsLoadingAddToCart] = useState(false);
  const [shoppingData, setShoppingData] = useState({
    products: [],
    site: null,
    from: null,
    to: null,
    id: null,
    guests: null,
  });

  function initStayData(refuge, dates, guests) {
    setShoppingData({
      products: [],
      site: refuge.site,
      from: dates.start,
      to: dates.end,
      id: refuge.site.id + dates.start + dates.end,
      guests,
    });
  }

  function resetProducts() {
    setShoppingData({
      ...shoppingData,
      products: [],
    });
  }

  function updateIsBookingEnabled() {
    const bookingEnabled =
      shoppingData.products &&
      shoppingData.guests ===
        shoppingData.products.reduce(
          (total, product) => total + product.quantity,
          0
        );
    setIsBookingEnabled({
      status: bookingEnabled,
      msg: bookingEnabled
        ? ""
        : `Has de seleccionar ${shoppingData.guests} règims.`,
    });
  }

  function addProduct(product, qty) {
    const existing = shoppingData.products.find(
      (guest) => guest.product_catalog_id === product.product_catalog_id
    );
    if (existing && qty === 0) {
      setShoppingData({
        ...shoppingData,
        products: [
          ...shoppingData.products.filter(
            (regim) => regim.product_catalog_id !== product.product_catalog_id
          ),
        ],
      });
    } else {
      setShoppingData({
        ...shoppingData,
        products: [
          ...shoppingData.products.filter(
            (regim) =>
              regim.product_catalog_id !== product.product_catalog_id ||
              regim.membership_id !== product.membershipId ||
              regim.rang_edat_id !== product.ageRangeId
          ),
          {
            description: "",
            agency_code_id: product.agencyCodeId,
            product_catalog_id: product.product_catalog_id,
            product_id: product.product_id,
            membership_id: product.membershipId,
            rang_edat_id: product.ageRangeId,
            price: product.price,
            deposit: product.deposit,
            membership_name: product.membershipName,
            product_name: product.name,
            rang_edat_name: product.ageRangeName,
            quantity: qty,
          },
        ],
      });
    }
  }

  function nights() {
    return moment(shoppingData.to)
      .startOf("day")
      .diff(moment(shoppingData.from).startOf("day"), "days");
  }

  const handleDatesFeedback = (refuge) => {
    console.log(refuge.status);
    if (refuge.status) {
      const statusObject = AvailabilityStatus(t)[refuge.status];
      setDatesFeedbackText(statusObject.msg);
    } else {
      setDatesFeedbackText("Hi ha problemes per calcular la disponibilitat.");
    }
  };

  const value = {
    isBookingEnabled,
    setIsBookingEnabled,
    updateIsBookingEnabled,
    isLoadingAddToCart,
    setIsLoadingAddToCart,
    shoppingData,
    setShoppingData,
    initStayData,
    resetProducts,
    addProduct,
    nights,
    handleDatesFeedback,
    datesFeedbackText,
  };

  return <StayContext.Provider value={value}>{children}</StayContext.Provider>;
}
