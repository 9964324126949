import React, { createRef, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import RefugeCard from "../Refuge/RefugeCard";
import Box from "@mui/material/Box";
import { H3 } from "../../styles/Headings.styled";
import SkeletonRefugeCard from "../Refuge/SkeletonRefugeCard";
import { Grid, LinearProgress } from "@mui/material";
import { useSearchContext } from "../../context/SearchContext";
import Typography from "@mui/material/Typography";
import { MdInfoOutline } from "react-icons/all";

const showSkeleton = () => {
  return (
    <>
      {Array(10)
        .fill()
        .map((item, index) => {
          return (
            <Grid key={index} item xxs={12} s={6} md={6} lg={6} xl={4}>
              <SkeletonRefugeCard />
            </Grid>
          );
        })}
    </>
  );
};

function LocationCards() {
  const { t } = useTranslation("search");
  const { setIsLoadingSearch, isLoadingSearch, places, availablePlacesCount } =
    useSearchContext();

  const [elRefs, setElRefs] = useState([]);

  useEffect(() => {
    setElRefs((refs) => {
      return Array(places?.length)
        .fill()
        .map((_, i) => refs[i] || createRef());
    });
  }, [places]);

  return (
    <>
      <Box
        style={{
          marginTop: "24px",
          marginBottom: "8px",
        }}
      >
        {isLoadingSearch ? (
          <H3>
            {t("search.loading_refuges")}
            <LinearProgress />
          </H3>
        ) : (
          <>
            <H3>{t("search.results", { count: availablePlacesCount })}</H3>
            <Typography variant="xSmall" color="primary.dark">
              Com et mostrem les disponibilitats? <MdInfoOutline />
            </Typography>
          </>
        )}
      </Box>
      <Box
        style={{
          marginTop: "16px",
        }}
      >
        <Grid container rowSpacing={"40px"} columnSpacing={"24px"}>
          {isLoadingSearch ? (
            showSkeleton()
          ) : (
            <>
              {places.map((item, i) => (
                <Grid
                  key={item.site.id}
                  item
                  xxs={12}
                  s={6}
                  md={6}
                  lg={6}
                  xl={4}
                >
                  <Box ref={elRefs[i]}>
                    <RefugeCard place={item} i={i} refProp={elRefs[i]} />
                  </Box>
                </Grid>
              ))}
            </>
          )}
        </Grid>
      </Box>
    </>
  );
}

export default React.memo(LocationCards);
