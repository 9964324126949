import React from "react";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import { getCart } from "../../../store/modules/order/selectors";
import Button from "@mui/material/Button";
import { Link } from "@mui/material";
import hiker from "../../../assets/images/stickers/backpack.png";
import BaseMessageScreen from "../../BaseMessageScreen";

const useStyles = makeStyles((theme) => ({
  success_color: {
    color: theme.palette.refus.main,
  },
  success_slogan: {
    fontWeight: 700,
    fontSize: theme.typography.fontSizes.xxl,
  },
}));

function CheckoutSuccess() {
  const { t } = useTranslation("general");
  const classes = useStyles();
  const cart = useSelector(getCart);

  return (
    <BaseMessageScreen>
      <Box
        sx={{
          marginBottom: "20px",
        }}
        className={`${classes.success_color} ${classes.success_slogan}`}
      >
        {t("checkout.succes_page.msg_slogan")}
      </Box>
      <Box>
        <img
          src={hiker}
          alt="Hiker.Payment Success"
          style={{ width: "200px", height: "200px" }}
        />
      </Box>
      <Typography
        sx={{
          marginTop: "30px",
          marginBottom: "20px",
        }}
        variant="bodyBold"
        gutterBottom
      >
        {t("checkout.succes_page.msg_success")}
      </Typography>
      <Box>
        <Typography variant="h5" color="primary.dark">
          {t("checkout.succes_page.msg_order_id", {
            bookingId: cart.bookingId || "No hi ha dades",
          })}
        </Typography>
        <Typography variant="h5" color="primary.dark">
          {t("checkout.succes_page.msg_email", {
            email: cart.customer ? cart.customer.email : "No hi ha dades",
          })}
        </Typography>
      </Box>
      <Box
        sx={{
          marginTop: "40px",
        }}
      >
        <Link href="/">
          <Button variant="rounded" color="success">
            Home
          </Button>
        </Link>
      </Box>
    </BaseMessageScreen>
  );
}

export default CheckoutSuccess;
