import { handleActions } from 'redux-actions';

import {
  setError,
  clearError,
  setMobileBottomNavShow,
  mobileBottomNavShow,
} from './actions';

export const defaultState = {
  errorMessage: '',
  mobileBottomNavShowing: true,
};

export default handleActions(
  {
    [setError]: (state, { payload }) => ({ ...state, errorMessage: payload.errorMessage }),
    [clearError]: (state, action) => defaultState,
    [setMobileBottomNavShow]: (state, { payload }) => ({ ...state, mobileBottomNavShowing: payload }),
    [mobileBottomNavShow]: (state, action) => defaultState,
  },
  defaultState,
);
