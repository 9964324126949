import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useMediaQuery } from "@mui/material";
import checkoutFormModel from "Screens/Checkout/FormModel/checkoutFormModel";
import {
  confirmOrder,
  payOrder,
  placeOrderAction,
  setCustomerData,
} from "../store/modules/order/actions";
import CustomerDataForm from "Screens/Checkout/CustomerData/CustomerDataForm";
import ReviewOrder from "Screens/Checkout/ReviewOrder/ReviewOrder";
import Payment from "Screens/Checkout/Payment/Payment";
import CheckoutSuccess from "Screens/Checkout/CheckoutSuccess/CheckoutSuccess";
import { useDispatch } from "react-redux";

export const CheckoutContext = createContext({});
export const useCheckoutContext = () => useContext(CheckoutContext);

export function CheckoutProvider({ children }) {
  const dispatch = useDispatch();
  const { formId, formField } = checkoutFormModel;
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [orderProcessing, setOrderProcessing] = useState(false);
  const [skipTPV, setSkipTPV] = useState(false);
  const [onSubmit, setOnSubmit] = useState(null);

  const handleStepCompletion = useCallback(
    async (values, actions) => {
      const currentStepData = generateStepData(activeStep);
      const { action } = currentStepData;

      if (activeStep === 0) {
        // If it's step 0, dispatch setCustomerData and await it before the main action
        await dispatch(setCustomerData({ customer: values }));
      }

      if (action) {
        try {
          await new Promise((promiseResolve, promiseReject) => {
            action(promiseResolve, promiseReject, dispatch);
          });

          if (actions) {
            actions.setSubmitting(false);
          }

          if (activeStep === stepData.names.length - 1) {
            // Handle last step completion
            console.debug("Order has been placed, process completed.");
          } else {
            console.debug("Order has been sent, next step.");
            setActiveStep((prevStep) => prevStep + 1);
          }
        } catch (error) {
          console.error(error);
          if (actions) {
            actions.setSubmitting(false);
          }

          // Handle error or show an error message
          // todo: showError
        }
      } else {
        // Handle invalid step action
        if (actions) {
          actions.setSubmitting(false);
        }

        console.error("Invalid step action");
      }
    },
    [activeStep]
  );

  const stepComponents = [
    <CustomerDataForm formField={formField} />,
    <ReviewOrder formField={formField} />,
    <Payment handleStepCompletion={handleStepCompletion} />,
    <CheckoutSuccess />,
  ];

  const stepData = {
    names: {
      0: isSmallScreen ? "Dades" : "Dades personals",
      1: isSmallScreen ? "Revisa" : "Revisa la reserva",
      2: "Pagament",
      3: "Confirmació",
    },
    actions: {
      0: (resolve, reject, dispatch) =>
        dispatch(placeOrderAction({ onComplete: resolve, onError: reject })),
      1: (resolve, reject, dispatch) => {
        dispatch(confirmOrder({ onComplete: resolve, onError: reject }));
        dispatch(payOrder({ onComplete: resolve, onError: reject }));
      },
      2: (resolve, reject, dispatch) =>
        dispatch(payOrder({ onComplete: resolve, onError: reject })),
    },
  };

  const generateStepData = (activeStep) => {
    return {
      steps: stepData.names,
      name: stepData.names[activeStep],
      action: stepData.actions[activeStep],
      component: stepComponents[activeStep],
    };
  };

  useEffect(() => {
    if (activeStep >= stepData.names.length) {
      setActiveStep(stepData.names.length - 1);
    }
  }, [activeStep, stepData.names]);

  const isLastStep = activeStep === stepData.names.length - 1;

  const handleBack = () => {
    setActiveStep((prevStep) => (prevStep === 0 ? 0 : prevStep - 1));
  };

  const skipTPVStep = () => {
    setSkipTPV(true);
    setActiveStep(activeStep + 1);
  };

  const value = useMemo(
    () => ({
      formId,
      formField,
      isSubmitting,
      setIsSubmitting,
      isLastStep,
      activeStep,
      setActiveStep,
      orderProcessing,
      handleStepCompletion,
      handleBack,
      generateStepData,
      skipTPV,
      setSkipTPV,
      setOnSubmit,
      onSubmit,
    }),
    [
      formId,
      formField,
      isSubmitting,
      activeStep,
      orderProcessing,
      handleStepCompletion,
      handleBack,
    ]
  );

  return (
    <CheckoutContext.Provider value={value}>
      {children}
    </CheckoutContext.Provider>
  );
}
