import Container from "@mui/material/Container";
import { Grid, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { flexBetween } from "../../styles/themes/commonStyles";
import { H1 } from "../../styles/Headings.styled";
import React from "react";
import HelpTags from "./dumbs/HelpTags";
import { useUIContext } from "../../context/UIContext";
import { HelpOther, HelpOtherList } from "./styles";

const HelpScreen = ({ title, tags, children }) => {
  const { isMedium, isMobile } = useUIContext();

  return (
    <Container disableGutters maxWidth="lg">
      <Box
        sx={{
          ...flexBetween,
          position: "relative",
        }}
      >
        <Box
          sx={{
            width: "60%",
            ...(isMedium && {
              width: "100%",
              mb: 4,
              px: "48px",
            }),
            ...(isMobile && { px: "0" }),
          }}
        >
          <Grid
            sx={{
              pl: { xxs: "24px", sm: "40px" },
              pr: { xxs: "24px", sm: "24px" },
            }}
            item
            xxs={12}
            order={{ xxs: 2 }}
          >
            {title && (
              <Box
                sx={{
                  ...flexBetween,
                  pt: { xxs: "24px", sm: "28px" },
                  pb: { xxs: "16px", sm: "0" },
                }}
              >
                <Box>
                  <HelpTags tags={tags} />
                  <H1>{title}</H1>
                </Box>
              </Box>
            )}
            <Box>{children}</Box>
            <HelpOther>
              <Typography variant="subtitle1" sx={{ mb: "8px" }}>
                Altres ajudes
              </Typography>
              <HelpOtherList>
                <li className="l1pvymst dir dir-ltr">
                  <div
                    aria-labelledby="article-card-1542"
                    className="c1vrv309 dir dir-ltr"
                  >
                    <Typography
                      variant="body2"
                      fontWeight="600"
                      color="primary.dark"
                      sx={{ mb: "8px" }}
                    >
                      Viatger
                    </Typography>
                    <div>
                      <h3
                        id="article-card-1542"
                        tabIndex="-1"
                        className="hpipapi dir dir-ltr"
                        elementtiming="LCP-target"
                      >
                        <a
                          href="/help/article/1542"
                          className="t1imbvyv t14utcvq dir dir-ltr"
                        >
                          Contacta con el equipo de Atención al Cliente de
                          Airbnb
                        </a>
                      </h3>
                      <div className="s12spazh s13lem7z dir dir-ltr">
                        La mayoría de los anfitriones y Viatgers son capaces de
                        resolver sus problemas rápidamente entre ellos. Descubre
                        cómo obtener ayuda antes, d…
                      </div>
                    </div>
                  </div>
                </li>
                <li className="l1pvymst dir dir-ltr">
                  <div
                    aria-labelledby="article-card-170"
                    className="c1vrv309 dir dir-ltr"
                  >
                    <Typography
                      variant="body2"
                      fontWeight="600"
                      color="primary.dark"
                      sx={{ mb: "8px" }}
                    >
                      Viatger
                    </Typography>
                    <div>
                      <h3
                        id="article-card-170"
                        tabIndex="-1"
                        className="hpipapi dir dir-ltr"
                        elementtiming="LCP-target"
                      >
                        <a
                          href="/help/article/170"
                          className="t1imbvyv t14utcvq dir dir-ltr"
                        >
                          ¿Qué ocurre si el anfitrión cancela la reserva?
                        </a>
                      </h3>
                      <div className="s12spazh s13lem7z dir dir-ltr">
                        Si el anfitrión cancela la reserva, recibirás un
                        reembolso completo de forma automática. Si la
                        cancelación se produce durante los 30 días pr…
                      </div>
                    </div>
                  </div>
                </li>
                <li className="l1pvymst dir dir-ltr">
                  <div
                    aria-labelledby="article-card-248"
                    className="c1vrv309 dir dir-ltr"
                  >
                    <Typography
                      variant="body2"
                      fontWeight="600"
                      color="primary.dark"
                      sx={{ mb: "8px" }}
                    >
                      Viatger
                    </Typography>
                    <div>
                      <h3
                        id="article-card-248"
                        tabIndex="-1"
                        className="hpipapi dir dir-ltr"
                        elementtiming="LCP-target"
                      >
                        <a
                          href="/help/article/248"
                          className="t1imbvyv t14utcvq dir dir-ltr"
                        >
                          Si tienes algún problema durante tu estancia
                        </a>
                      </h3>
                      <div className="s12spazh s13lem7z dir dir-ltr">
                        Si surge algún imprevisto durante tu estancia, lo
                        primero que debes hacer es contactar con el anfitrión
                        para intentar encontrar una solución…
                      </div>
                    </div>
                  </div>
                </li>
              </HelpOtherList>
            </HelpOther>
          </Grid>
        </Box>
        <Box
          sx={{
            width: "36.33%",
            ...(isMedium && {
              width: "0%",
            }),
          }}
        ></Box>
      </Box>
    </Container>
  );
};

export default HelpScreen;
