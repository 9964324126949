import { combineReducers } from "redux";
import refuges from "./modules/refuges/reducers";
import config from "./modules/config/reducers";
import ui from "./modules/ui/reducers";
import language from "./modules/language/reducers";
import order from "./modules/order/reducers";
import search from "./modules/search/reducers";

const rootReducer = combineReducers({
  refuges,
  config,
  ui,
  language,
  order,
  search,
});

export default rootReducer;
