import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { Outlet, ScrollRestoration, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Header from './Header';
import { displayOnDesktop, displayOnMobile } from '../../styles/themes/commonStyles';
import { useUIContext } from '../../context/UIContext';
import MobileBottomNav from './MobileBottomNav';
import CartDrawer from './CartDrawer';
import Footer from './Footer/Footer';
import DetailHeader from '../Refuge/DetailHeader';

export function MaterialLayout() {
  const {
    page, mobileLocationsDrawerOpen, isMobile,
  } = useUIContext();

  return (
    <main id="root">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100vh',
        }}
      >
        <Box sx={displayOnDesktop}>
          <CartDrawer />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            height: 100,
          }}
        >
          {page === 'home'
            && <Header />}
          {(page === 'place') && (
            isMobile ? <DetailHeader /> : <Header />
          )}
          <Container maxWidth="false" disableGutters>
            <Outlet />
          </Container>
          <Box sx={displayOnDesktop}>
            <Footer />
          </Box>
        </Box>
        { (mobileLocationsDrawerOpen && (page === 'home')) && (
          <Box sx={displayOnMobile}>
            <MobileBottomNav />
          </Box>
        )}
      </Box>
    </main>
  );
}
