import styled from "styled-components";
import { COLORS } from "../../styles/colors";

export const HelpImageSrc = styled.img`
  height: auto;
  width: 100%;
  position: static;
`;

export const HelpSectionContent = styled.div`
  p {
    margin: 8px 0 16px;
  }
`;

export const HelpSectionTitle = styled.h2`
  margin-top: 48px !important;
  margin-bottom: 16px !important;
`;

export const Divider = styled.div`
  border-bottom: 1px solid ${COLORS.refusLightGrey};
  margin-top: 24px;
  margin-bottom: 24px;
`;

export const HelpOtherTitle = styled.h2`
  margin-top: 48px !important;
  margin-bottom: 16px !important;
`;

export const HelpOther = styled.section`
  margin-top: 48px !important;
  margin-bottom: 80px !important;
`;

export const HelpOtherList = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;

  li {
    margin: 0;
    padding: 24px 0;
    border-bottom: 1px solid #dddddd;
  }

  h3 {
    font-size: 16px;
    margin-bottom: 4px;
  }

  .tag {
    font-size: 14px;
  }
`;
