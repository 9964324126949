import { createContext, useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectAllRefuges } from "../store/modules/refuges/selectors";
import {
  getAgencyCodeId,
  getAgencyConditions,
  getAgencyDates,
  getForfaits,
  getPickupSites,
} from "../store/modules/config/selectors";
import { availabilityService } from "../modules/Availability/AvailabilityService";
import { sortMethods } from "../utils/sortMethods";
import {
  selectFilters,
  selectIsLoadingSearch,
  selectIsNewSearch,
} from "../store/modules/search/selectors";
import {
  updateIsNewSearch,
  updateLoadingSearch,
} from "../store/modules/search/actions";

export const SearchContext = createContext({});
export const useSearchContext = () => useContext(SearchContext);

export function SearchProvider({ children }) {
  const dispatch = useDispatch();
  const [sortState, setSortState] = useState("status");

  const allPlaces = useSelector(selectAllRefuges);
  const searchFilters = useSelector(selectFilters);
  const agencyDates = useSelector(getAgencyDates);
  const pickupSites = useSelector(getPickupSites);
  const forfaits = useSelector(getForfaits);
  const agencyCodeId = useSelector(getAgencyCodeId);
  const agencyConditions = useSelector(getAgencyConditions);
  const isLoadingSearch = useSelector(selectIsLoadingSearch);
  const isNewSearch = useSelector(selectIsNewSearch);

  const { min_hours_booking_allowed_to_date: dateHourOffset } =
    agencyConditions;
  const { open: agencyOpenDate, close: agencyCloseDate } = agencyDates;
  const [guests, setGuests] = useState(searchFilters.peopleNumber);
  const [checkIn, setCheckIn] = useState(searchFilters.startDate);
  const [checkOut, setCheckOut] = useState(searchFilters.endDate);

  const { startAllowedDate, endAllowedDate, startDate, endDate } =
    availabilityService.requestAvailabilityDates({
      checkIn,
      checkOut,
      agencyOpenDate,
      agencyCloseDate,
      dateHourOffset,
    });

  const availablePlaces = availabilityService.filterAvailableSites(allPlaces);

  const value = {
    isLoadingSearch,
    isNewSearch,
    setIsNewSearch: (isNew) => dispatch(updateIsNewSearch({ isNew })),
    setIsLoadingSearch: (isLoading) =>
      dispatch(updateLoadingSearch({ isLoading })),
    searchFilters,
    checkIn,
    setCheckIn,
    checkOut,
    setCheckOut,
    guests,
    setGuests,
    pickupSites,
    forfaits,
    agencyCodeId,
    startAllowedDate,
    endAllowedDate,
    startDate,
    endDate,
    places: [].concat(allPlaces).sort(sortMethods[sortState].method),
    availablePlacesCount: availablePlaces.length,
  };

  return (
    <SearchContext.Provider value={value}>{children}</SearchContext.Provider>
  );
}
