import { createActions } from "redux-actions";

import {
  RESET_SEARCH,
  UPDATE_IS_NEW,
  UPDATE_LOADING_SEARCH,
  UPDATE_SEARCH_FILTERS,
} from "./actionTypes";

export const {
  resetSearch,
  updateSearchFilters,
  updateIsNewSearch,
  updateLoadingSearch,
} = createActions(
  RESET_SEARCH,
  UPDATE_SEARCH_FILTERS,
  UPDATE_IS_NEW,
  UPDATE_LOADING_SEARCH
);
