import React from "react";
import { Box } from "@mui/system";
import HelpSectionHeading from "./HelpSectionHeading";
import { Divider, HelpSectionContent } from "../styles";

const HelpSection = ({ title, description }) => (
  <Box sx={{ my: "16px" }}>
    <HelpSectionHeading title={title} />
    <HelpSectionContent
      dangerouslySetInnerHTML={{ __html: description ?? "" }}
    />
    <Divider />
  </Box>
);

export default HelpSection;
