import { Outlet } from 'react-router-dom';
import { MaterialLayout } from '../components/Layout/MaterialLayout';

function Root() {
  return (
    <main>
      <MaterialLayout>
        <Outlet />
      </MaterialLayout>
    </main>
  );
}

export default Root;
