import React from "react";
import { FaHome, FaPhoneAlt, FaRoute } from "react-icons/fa";
import { Tab, Tabs } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useUIContext } from "../../context/UIContext";
import "./mobileFooter.css";

function MobileBottomNav() {
  const navigate = useNavigate();
  const {
    setCartOpen,
    isMobileBottomNavShowing,
    setMobileLocationsDrawerOpen,
  } = useUIContext();
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 0) {
      setMobileLocationsDrawerOpen(false);
      setCartOpen(false);
      navigate("/");
    }
    if (newValue === 1) {
      navigate("/contact");
    }
    if (newValue === 2) {
      setCartOpen(true);
    }
  };

  console.log(isMobileBottomNavShowing);
  return (
    isMobileBottomNavShowing && (
      <div className={`nav`}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="icon label tabs example"
        >
          <Tab icon={<FaHome />} label="Inici" />
          <Tab icon={<FaPhoneAlt />} label="Contacte" />
          <Tab icon={<FaRoute />} label="Itinerari" />
        </Tabs>
      </div>
    )
  );
}

export default MobileBottomNav;
