import styled from 'styled-components';
import { COLORS } from '../../styles/colors';

export const RefugeListContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 40px;
`;

export const CartItemsContainer = styled.div`
  margin-top: 24px;
`;

export const RefugeContainer = styled.table`
  display: flex;
  width 100%;
  justify-content: space-between;
 
  flex-direction: column;
`;

export const PayContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const PayButtonWrapper = styled.div`
  width: 300px;
  margin-left: auto !important;
  margin-top: 32px !important;
  max-width: 400px !important;
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 84px !important;
  z-index: 1 !important;
`;

export const PayButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 105px;
  border-radius: 32px;
  border: none;
  background-color: ${COLORS.refusLightGreen};
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: ${COLORS.white};
`;

export const PayButtonSublabelWrapper = styled.ul`
  color: ${COLORS.refusTextGrey};
  font-size: 12px !important;
  font-weight: normal;
  line-height: 15px !important;
  align-items: center !important;
  display: flex !important;
  flex-direction: column !important;
  list-style-type: none !important;
  margin: 8px 0px 0px !important;
  padding: 0px !important;
  text-align: center !important;
`;

export const PayButtonSublabel = styled.li`
  margin-top: 8px !important;
  white-space: normal;
`;

export const TableColumn = styled.tr`
  display: flex;
`;

export const TableRow = styled.tr`
  display: flex;
  width 100%;
  align-items: left;
  white-space:wrap;
`;

export const TableRowHeader = styled(TableRow)`
  border-bottom: 1px solid ${COLORS.refusLightGrey};
`;

export const TableCellHeader = styled.td`
  display: flex;
  flex: 1;
  text-transform: capitalize;
  font-weight: 500;
  align-items: center;
  justify-content: center;
  text-align: center;
  @media all and (max-width: 1024px) {
    font-size: 12px;
  }
  @media all and (max-width: 768px) {
    overflow: hidden;
  }
`;

export const TableCellHeaderWide = styled(TableCellHeader)`
  flex: 2;
`;

export const TableCellHeaderWideDate = styled(TableCellHeader)`
  flex: 5;
  text-transform: none;
  align-items: left;
  justify-content: left;
  margin-bottom: 10px;
`;

export const StayData = styled.div`
  display: inline-block !important;
  background-color: ${COLORS.refusLightGrey};
  float: left !important;
  height: 40px;
  width: 40px;
  text-align: center !important;
  border-radius: 3px !important;
  font-size: 15px !important;
`;

export const StaySiteName = styled.div`
  overflow-wrap: break-word !important;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif !important;
  font-size: 15px !important;
  font-weight: 800 !important;
  line-height: 1.44444em !important;
  color: rgb(72, 72, 72) !important;
  margin: 0px !important;
`;

export const DateMonth = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 8px;
  color: ${COLORS.refusTextGrey};
  margin-bottom: 6px;
`;

export const StayDataText = styled.div`
  display: inline-block !important;
  float: left !important;
`;

export const DateDay = styled.div`
  overflow-wrap: break-word !important;
  font-weight: bold;
  font-size: 14px;
  line-height: 8px;
  color: ${COLORS.refusTextGrey};
  margin: 0px !important;
`;

export const TableCell = styled.td`
  display: flex;
  flex: 1;
  padding: 10px;
  align-items: left; 
  font-weigth: 500;
  align-items: center;
  justify-content: center;
  text-align: center;
  @media all and (max-width: 1024px) {
    font-size: 12px;
  }
  @media all and (max-width: 768px) {
    overflow: hidden;
  }
`;

export const TableCellWide = styled(TableCellHeader)`
  flex: 2;
`;

export const MainTitleTable = styled.h2`
  color: #717171;
  text-transform: uppercase;
`;

export const CartButton = styled.button`
  background-color: rgb(255, 90, 95);
  border: 0;
  color: inherit;
  cursor: pointer;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  padding: 10px 20px;
  border-radius: 2px;
  color: white;
  font-weight: 700;
  width: 200px;
  margin: 20px;
  align-self: flex-end;
  @media (max-width: 768px) {
    width: auto;
  }
`;

export const TableRowPrice = styled(TableCellHeader)`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  border-top: 2px solid lightgrey;
  height: 25px;
  padding: 10px 0;
`;

export const TotalRefuge = styled.p`
  font-size: 16px;
`;

export const TableRowFinalPrice = styled(TableCellHeader)`
  font-size: 16px;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid ${COLORS.refusLightGreen};
  padding: 20px 20px 0;
  height: 15px;
  font-weight: 700;
`;

export const TotalPriceAll = styled.p`
  font-size: 20px;
`;

export const TableRowDeposit = styled(TableCellHeader)`
  justify-content: flex-end;
  height: 25px;
  margin-bottom: 25px;
  font-size: 16px;
  font-weight: 700;
  padding: 10px 20px;
`;

export const TotalDeposit = styled.p`
`;

export const TrashButton = styled.div`
  color: rgb(255, 90, 95);
  border: 0;
  cursor: pointer;
  padding-top: 5px
`;

export const NoRefugesMessage = styled.h1`
`;
