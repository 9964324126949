import React from "react";
import HelpScreen from "../HelpScreen";
import HelpSection from "../dumbs/HelpSection";
import Box from "@mui/material/Box";
import HelpImage from "../dumbs/HelpImage";
import cancelImage from "Assets/images/help/cancel-word-on-keyboard.jpg";

const CancellationPage = () => {
  const title = "Com funcionen les cancel·lacions";
  const image = cancelImage;
  const tags = "Ajuda · Cancel·lació";

  return (
    <HelpScreen title={title} tags={tags}>
      <Box>
        {image && <HelpImage image={image} />}
        <Box section-id="cancelation-type">
          <HelpSection
            title="Cancel·lació de la reserva per part de l'usuari"
            description="<p>El refugi retindrà el següent percentatge de la paga i senyal:</p>
<ul>
  <li><b>25%</b> de la paga i senyal si s'anul·la <b>abans de 15 dies</b> a la data de sortida.</li>
  <li><b>50%</b> de la paga i senyal si s'anul·la <b>entre 7 i 15 dies</b> a la data de sortida.</li>
  <li><b>100%</b> de la paga i senyal si s'anul·la dins dels <b>7 dies abans</b> de la sortida.</li>
</ul>
<p>Els percentatges indicats anteriorment també s'aplicaran si la cancel·lació és per causes meteorològiques.</p>
<p>En cas d'anul·lació, Trekking Carros de Fuego, descomptarà de la quantitat a tornar, <b>2 € per reserva, en concepte de despeses de gestió i despeses bancàries</b>.</p>"
          />
          <HelpSection
            title='No presentació per part del client al refugi ("no show") sense previ avís per la seva part'
            description="<p>En els supòsits de no presentació per part dels clients en refugi de què es tracti
sense haver-ne avisat prèviament (el que es coneix comunament com “NO SHOW”), cada refugi determina la seva pròpia política de penalitzacions, la qual serà
degudament informada a l'Usuari abans de la formalització de la reserva.</p>
<p><b>L'usuari perdrà el 100% de la paga i senyal.</b></p>"
          />
        </Box>
      </Box>
    </HelpScreen>
  );
};

export default CancellationPage;
