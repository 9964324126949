import { createContext, useContext, useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/styles";
import { useSelector } from "react-redux";
import { selectMobileBottomNavShowing } from "../store/modules/ui/selectors";

export const UIContext = createContext({});
export const useUIContext = () => useContext(UIContext);

export function UIProvider({ children }) {
  const theme = useTheme();

  const [page, setPage] = useState("home");
  const [cartOpen, setCartOpen] = useState(false);
  const [mobileLocationsDrawerOpen, setMobileLocationsDrawerOpen] =
    useState(false);

  const [showSearchBox, setShowSearchBox] = useState(false);
  const [cart, setCart] = useState([]);
  const [showCart, setShowCart] = useState(false);

  const isSmall = useMediaQuery(theme.breakpoints.down("xs"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isMedium = useMediaQuery(theme.breakpoints.down("lg"));

  const isMobileBottomNavShowing = useSelector(selectMobileBottomNavShowing);
  const isHomePage = page === "home";

  const value = {
    isSmall,
    isMobile,
    isMedium,
    page,
    setPage,
    cartOpen,
    setCartOpen,
    mobileLocationsDrawerOpen,
    setMobileLocationsDrawerOpen,
    showSearchBox,
    setShowSearchBox,
    showCart,
    setShowCart,
    isMobileBottomNavShowing,
    isHomePage,
  };

  return <UIContext.Provider value={value}>{children}</UIContext.Provider>;
}
