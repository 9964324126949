import React from "react";
import Box from "@mui/material/Box";
import { Link } from "@mui/material";
import { useSelector } from "react-redux";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useTheme } from "@mui/styles";
import { getCart } from "../../store/modules/order/selectors";
import { useUIContext } from "../../context/UIContext";
import CartBadge from "../Layout/CartBadge";
import {
  flexBetween,
  roundWhiteIconShape,
} from "../../styles/themes/commonStyles";

function DetailHeader() {
  const { page, setCartOpen } = useUIContext();
  const theme = useTheme();
  const cart = useSelector(getCart);

  const handleCartBadgeClick = () => {
    setCartOpen(true);
  };

  return (
    <Box
      sx={{
        zIndex: 1,
        background: "none",
        left: "0",
        padding: "25px 13px",
        position: "absolute",
        right: "0px",
        top: "0px",
        paddingTop: "16px",
        ...flexBetween,
        alignItems: "center",
        px: { xxs: "16px", sm: "40px" },
      }}
    >
      <Box sx={roundWhiteIconShape}>
        <Link href="/">
          <ArrowBackIosNewIcon
            sx={{
              fontSize: 20,
              mt: "6px",
            }}
          />
        </Link>
      </Box>
      <CartBadge
        handleClick={handleCartBadgeClick}
        badgeContent={cart && cart.length}
      />
    </Box>
  );
}

export default React.memo(DetailHeader);
